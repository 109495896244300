import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import {
  CircleMarker,
  MapContainer,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType } from "src/Types";
import "./SelectPointsMap.css";

interface SelectPointsMapProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class SelectPointsMap extends React.Component<SelectPointsMapProps> {
  center: LatLng =
    this.props.state.current_user.center_area || new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 11;

  calcDistance(pointA: LatLng, pointB: LatLng): number {
    return Math.sqrt(
      Math.pow(pointA.lat - pointB.lat, 2) +
        Math.pow(pointA.lng - pointB.lng, 2)
    );
  }

  AreaPoints = () => {
    useMapEvents({
      click: (event) => {
        const { lat, lng } = event.latlng;
        const newPoint = new LatLng(lat, lng);

        // Check if the point already exists
        const existingPointIndex =
          this.props.state.newAnalysisLayer.destinations.findIndex(
            (destination) =>
              this.calcDistance(destination.geom, newPoint) <= 0.0001
          );

        if (existingPointIndex !== -1) {
          // Remove the point if it already exists
          this.props.state.newAnalysisLayer.destinations.splice(
            existingPointIndex,
            1
          );
          this.props.state.newAnalysisLayer.destinations.filter(
            (item) => item.geom !== newPoint
          );
        } else {
          // Add the new point
          this.props.state.newAnalysisLayer.destinations.push({
            name: "",
            geom: newPoint,
          });
        }

        this.props.updateState(this.props.state);
      },
    });

    return (
      <>
        {this.props.state.newAnalysisLayer.destinations.map((point, idx) => (
          <CircleMarker
            key={idx}
            center={point.geom}
            radius={5}
            color="#5d87ff"
          />
        ))}
      </>
    );
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        id={"PointsMap"}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <this.AreaPoints />
      </MapContainer>
    );
  }
}

import React from "react";
import { GoXCircleFill } from "react-icons/go";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./ProjectScreen.css";

interface ProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ProjectScreen extends React.Component<ProjectScreenProps> {
  render() {
    return (
      <div id="ProjectScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title={this.props.state.project.name || "Project"} />
            </li>
            <li>
              <MainButton
                onClick={() => {
                  this.props.stateHandler.deleteProject(this.props.updateState);
                }}
                icon={<GoXCircleFill size={12} />}
                className="MainBtn Danger"
              >
                Project verwijderen
              </MainButton>
            </li>
          </ul>
        </div>
        {/* <div id="ProjectScreen-ContentHolder">
          <body>
            <table className="InfoTable">
              <tbody className="InfoTable-tbody">
                <tr className="InfoTableTr">
                  <td>
                    <SectionTitle subtitle={true}>Type Scenario :</SectionTitle>
                  </td>
                  <td>{this.props.state.project.typescenario}</td>
                </tr>
                <tr className="InfoTableTr">
                  <td>
                    <SectionTitle subtitle={true}>Jaar brondata :</SectionTitle>
                  </td>
                  <td>{this.props.state.project.sourcedataDate}</td>
                </tr>
                <tr className="InfoTableTr">
                  <td>
                    <SectionTitle subtitle={true}>
                      Bestemmingen :
                    </SectionTitle>
                  </td>
                  <td>
                    {this.props.state.project.analysisLayers.map((layer) => (
                      <li>{layer}</li>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </body>
        </div> */}
      </div>
    );
  }
}

import React from "react";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import MarkdownViewer from "../../components/MarkdownViewer";
import { AppStateType } from "../../Types";
import "./DocsScreen.css";

interface DocsScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class DocsScreen extends React.Component<DocsScreenProps> {
  render() {
    return (
      <div id="DocsScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="scenario manager Handleiding" />
            </li>
          </ul>
        </div>

        <div id="DocsScreen-ContentHolder">
          <MarkdownViewer />
        </div>
      </div>
    );
  }
}

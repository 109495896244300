import React from "react";
import { FaPlus } from "react-icons/fa";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { IoLogoBuffer } from "react-icons/io5";
import Button from "src/components/Button";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import SectionTitle from "../../components/SectionTitle";
import { TextInput, TextInputType } from "../../components/TextInput";
import UserLogsPopUp from "../../components/UserLogsPopUp";
import "./AdminScreen.css";

interface AdminScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class AdminScreen extends React.Component<AdminScreenProps> {
  render() {
    return (
      <>
        {(() => {
          if (this.props.state.userLogsPopUpVisable) {
            return (
              <UserLogsPopUp
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></UserLogsPopUp>
            );
          }
        })()}
        <div id="AdminScreen">
          <div className="top-bar">
            <ul>
              <li>
                <MenuTitle title="scenario manager | Admin" />
              </li>
            </ul>
          </div>
          <div id="AdminScreen-ContentHolder">
            <SectionTitle subtitle={true}>
              Een gebruikersaccount toevoegen
            </SectionTitle>
            <div className="NewUserFormContainer">
              <div className="NewUserFormItem">
                <TextInput
                  type={TextInputType.Email}
                  onChange={(newValue) => {
                    this.props.stateHandler.updateNewUser(
                      { email: newValue },
                      this.props.updateState
                    );
                  }}
                  placeholder="E-mail"
                ></TextInput>
              </div>
              <div className="NewUserFormItem">
                <TextInput
                  type={TextInputType.Password}
                  onChange={(newValue) => {
                    this.props.stateHandler.updateNewUser(
                      { password: newValue },
                      this.props.updateState
                    );
                  }}
                  placeholder="Wachtwoord"
                ></TextInput>
              </div>

              <div className="NewUserFormItem">
                <TextInput
                  type={TextInputType.UnsignedInteger}
                  onChange={(newValue: any) => {
                    this.props.stateHandler.updateNewUser(
                      { area_id: newValue },
                      this.props.updateState
                    );
                  }}
                  placeholder="Gebied ID"
                ></TextInput>
              </div>
              <div className="NewUserFormItem">
                <Button
                  onClick={() => {
                    this.props.stateHandler.createNewUser(
                      this.props.updateState
                    );
                  }}
                >
                  <FaPlus size={12} />
                  Opslaan
                </Button>
              </div>
            </div>
            <div className="NewUserFormBtn">
              {/* <MainButton
                onClick={() => {
                  this.props.stateHandler.createNewUser(
                    this.props.updateState
                  );
                }}
                icon={<FaPlus size={12} />}
                className="MainBtn OK"
              >
                Opslaan
              </MainButton> */}
            </div>
            <SectionTitle subtitle={true}>De gebruikerslijst</SectionTitle>
            <table className="Users-table">
              <thead>
                <tr>
                  <th>E-mail</th>
                  <th>Actief</th>
                  <th>Gebied</th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.listUsers.map((user) => (
                  <tr>
                    <td>{user.email}</td>
                    <td>
                      {user.isActive ? (
                        <ImCheckboxChecked />
                      ) : (
                        <ImCheckboxUnchecked />
                      )}
                    </td>
                    <td>{user.area}</td>
                    <td>
                      {
                        <MainButton
                          onClick={() => {
                            this.props.stateHandler.loadUserlogs(
                              user.id,
                              this.props.updateState
                            );

                            // this.props.updateState(this.props.state);
                          }}
                          icon={<IoLogoBuffer size={12} />}
                          className="MainBtn"
                        >
                          Log
                        </MainButton>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Button from "../../components/Button";
import { AppStateType } from "../../Types";
import "./LoginScreen.css";

interface LoginScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class LoginScreen extends React.Component<LoginScreenProps> {
  render() {
    return (
      <div id={"LoginScreen"}>
        <div id={"LoginScreen-Holder"}>
          <svg
            id={"LoginScreen-Logo"}
            viewBox="0 0 600 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            enable-background="new 0 0 500 500"
          >
            <path
              fill="#FDFEFF"
              opacity="1.000000"
              stroke="none"
              d="
M279.000000,155.000000 
	C186.000015,155.000000 93.500023,155.000000 1.000025,155.000000 
	C1.000017,103.666687 1.000017,52.333366 1.000008,1.000039 
	C143.999969,1.000026 286.999939,1.000026 429.999939,1.000013 
	C429.999939,52.333294 429.999939,103.666588 429.999969,154.999939 
	C379.833344,155.000000 329.666656,155.000000 279.000000,155.000000 
M97.273247,20.574276 
	C102.763725,19.528877 108.362251,17.211412 113.725258,17.666904 
	C132.934464,19.298399 144.906235,32.985733 145.063049,52.398510 
	C145.162659,64.728012 145.202301,77.061195 144.972168,89.387184 
	C144.931061,91.589035 143.870316,94.083282 142.532455,95.888123 
	C139.197586,100.387047 139.165115,106.070023 142.957047,110.008575 
	C146.444031,113.630386 152.123657,114.205513 156.165848,111.346077 
	C160.613327,108.199944 162.658554,101.718079 159.128510,97.751999 
	C155.217102,93.357422 155.780670,88.708908 155.762421,83.840721 
	C155.724945,73.843323 155.868134,63.843658 155.694778,53.849125 
	C155.622955,49.708153 155.343964,45.486099 154.450546,41.458099 
	C149.465775,18.984028 129.384445,4.737906 106.311279,7.076556 
	C84.418243,9.295590 67.451660,28.533609 67.426201,51.207077 
	C67.406738,68.536110 67.556595,85.867004 67.315422,103.192680 
	C67.268982,106.528801 66.135010,110.005760 64.804863,113.119637 
	C63.702515,115.700256 61.763294,116.847694 58.415352,115.015121 
	C53.237854,112.181076 47.461372,114.434731 44.846500,119.510078 
	C42.353283,124.349297 43.952492,130.086868 48.624340,133.063950 
	C53.212978,135.988022 58.757481,134.833038 62.347366,130.287033 
	C63.551361,128.762375 64.699692,127.024811 66.271492,125.987915 
	C74.342209,120.663795 78.043671,113.190712 78.019249,103.636826 
	C77.974953,86.307869 77.983994,68.978676 78.014549,51.649658 
	C78.039162,37.694065 84.434425,27.544855 97.273247,20.574276 
M10.254951,110.922768 
	C10.505240,112.230072 10.749664,113.538536 11.006697,114.844513 
	C14.933697,134.797546 34.731266,150.502518 54.707745,149.513290 
	C80.150169,148.253418 97.944885,129.582687 98.026871,103.975143 
	C98.081810,86.816170 98.070053,69.656807 98.020012,52.497814 
	C98.004982,47.340801 99.597931,43.089783 104.164192,39.965542 
	C105.059738,40.474693 105.874054,41.019272 106.752808,41.423889 
	C112.117805,43.894199 118.374313,41.866650 120.848961,36.885086 
	C123.201057,32.150219 121.432899,25.970215 116.959023,23.289114 
	C112.045197,20.344360 105.908821,21.937067 102.581505,27.004782 
	C101.955414,27.958351 101.474358,29.236637 100.575485,29.746628 
	C89.900673,35.803188 87.418343,45.366112 87.626015,56.882477 
	C87.944023,74.517754 88.024124,92.211494 86.937912,109.802170 
	C85.919518,126.294533 70.517258,139.084213 53.772358,138.986908 
	C36.987194,138.889374 22.018751,125.879692 20.992395,109.379501 
	C20.114927,95.272934 20.343533,81.089233 20.403128,66.940994 
	C20.412964,64.606178 21.629404,61.956474 23.052814,60.011997 
	C26.351849,55.505276 26.290752,49.664818 22.378660,45.983509 
	C18.152077,42.006256 12.253306,42.023567 7.970014,46.025787 
	C4.105460,49.636738 4.070621,55.620213 7.335441,60.084599 
	C8.749668,62.018448 9.831287,64.689888 9.876942,67.049255 
	C10.154062,81.370903 10.088675,95.699181 10.254951,110.922768 
M135.782120,107.499359 
	C135.782166,93.682854 135.627853,79.863197 135.909332,66.052422 
	C135.967300,63.208057 137.145386,60.076271 138.691269,57.637318 
	C141.870224,52.621826 141.504318,47.237938 137.005981,43.267990 
	C133.075409,39.799122 126.851158,40.081917 122.996216,43.904522 
	C119.177414,47.691284 119.192154,53.414101 122.534409,58.003059 
	C123.862617,59.826721 124.923103,62.334938 124.945366,64.543198 
	C125.133324,83.185265 125.031784,101.830101 125.072701,120.473976 
	C125.079674,123.654762 124.838638,126.331818 122.310204,129.004028 
	C118.519081,133.010696 119.384109,139.141632 123.402000,142.912476 
	C127.397057,146.661896 133.215134,146.678726 137.428909,142.953049 
	C141.325165,139.508118 142.314651,133.400497 138.748611,129.480270 
	C136.067993,126.533394 135.635925,123.538193 135.753586,119.983971 
	C135.880203,116.159492 135.781967,112.327576 135.782120,107.499359 
M40.561600,83.486160 
	C40.561653,67.675446 40.424389,51.862438 40.688225,36.056126 
	C40.735775,33.207306 41.934441,30.072380 43.476036,27.618961 
	C46.456596,22.875454 46.222054,17.250467 42.179535,13.575033 
	C38.034786,9.806653 31.802572,9.963553 27.765043,13.937927 
	C24.087610,17.557838 24.133902,23.692465 27.330124,28.090143 
	C28.734499,30.022425 29.859674,32.672741 29.885281,35.010468 
	C30.089426,53.648239 29.954636,72.289444 30.043146,90.929031 
	C30.057846,94.024948 29.603279,96.456093 27.230692,98.908165 
	C23.583899,102.677132 24.169094,108.701805 27.801563,112.489258 
	C31.336990,116.175507 36.763309,116.746941 40.989384,113.878029 
	C45.292728,110.956657 47.571148,104.638870 44.259071,100.797043 
	C39.838547,95.669479 40.668056,90.149017 40.561600,83.486160 
M200.167603,100.614799 
	C198.261581,104.895264 196.355560,109.175720 193.938141,114.604645 
	C189.734756,105.206245 186.161285,97.216263 182.587814,89.226273 
	C182.216782,89.332672 181.845749,89.439064 181.474716,89.545456 
	C179.285553,100.849739 177.096390,112.154022 174.909775,123.445152 
	C178.321762,124.617851 179.637100,123.830170 180.075027,120.636703 
	C180.897400,114.640030 182.159653,108.703674 183.468185,101.476250 
	C187.172806,109.652634 190.378357,116.727539 193.975739,124.667229 
	C197.622406,116.539330 200.765030,109.534889 204.397491,101.438660 
	C205.748520,108.800735 207.032104,114.776398 207.884491,120.812935 
	C208.416260,124.578842 210.140823,124.500351 213.035812,123.261147 
	C210.707794,111.641541 208.428772,100.266541 205.871185,87.501083 
	C203.656815,92.636948 202.083084,96.286949 200.167603,100.614799 
M285.856842,45.694260 
	C278.801758,39.333843 271.746674,32.973423 265.187561,27.060143 
	C265.187561,36.585804 265.090088,47.361454 265.329285,58.129620 
	C265.354919,59.282288 267.343170,60.391357 268.420990,61.520653 
	C268.975037,61.175152 269.529114,60.829647 270.083160,60.484146 
	C270.083160,52.511040 270.083160,44.537930 270.083160,35.542843 
	C278.991058,44.844345 287.056915,53.266590 295.704224,62.296001 
	C295.704224,49.567684 295.704224,38.014931 295.704224,26.533964 
	C292.330780,25.336430 291.224915,26.492817 291.284302,29.664444 
	C291.409332,36.345253 291.322327,43.030033 291.322327,49.713181 
	C290.794617,49.938999 290.266907,50.164818 289.739227,50.390636 
	C288.611816,49.000137 287.484406,47.609638 285.856842,45.694260 
M416.071167,40.413242 
	C413.526489,30.546104 404.986908,24.075207 396.218231,25.369633 
	C385.518250,26.949154 378.553131,36.165802 380.068481,46.739807 
	C381.369843,55.820530 390.635620,62.856529 399.915375,61.810627 
	C410.349640,60.634602 417.169617,51.988266 416.071167,40.413242 
M343.027313,92.914055 
	C348.556793,91.889320 353.123474,93.754662 356.765900,97.765930 
	C360.651123,95.939377 359.722748,94.252136 357.218079,92.298950 
	C350.230865,86.850235 340.570740,87.071793 333.810669,92.935646 
	C327.383942,98.510345 325.844238,108.783218 330.328094,116.171402 
	C334.720886,123.409531 343.857422,126.627480 351.570557,123.653175 
	C358.623352,120.933517 362.616180,113.991798 361.275482,106.454330 
	C357.063599,106.454330 352.807129,106.454330 348.298859,106.454330 
	C348.240326,107.624672 347.976044,109.338013 348.178802,109.395195 
	C350.895844,110.161461 353.678101,110.696487 356.444031,111.288940 
	C354.968170,118.041565 349.463013,121.700500 342.972015,120.220787 
	C336.937927,118.845222 332.673126,113.825249 332.116486,107.443062 
	C331.584686,101.345604 335.308624,96.026566 343.027313,92.914055 
M259.896545,92.599113 
	C258.493896,91.186722 257.091278,89.774330 254.947083,87.615227 
	C254.947083,99.649391 254.888901,110.287163 255.040573,120.921944 
	C255.055099,121.941208 256.376740,122.941833 257.091583,123.951103 
	C257.777344,122.903488 258.867950,121.924171 259.058777,120.793053 
	C259.386169,118.852737 259.152405,116.817589 259.152618,114.822250 
	C259.153168,109.680435 259.152802,104.538620 259.152802,100.013885 
	C268.107910,108.046654 276.841309,115.880554 285.599731,123.736877 
	C285.599731,113.428345 285.687683,102.541641 285.455505,91.661758 
	C285.433716,90.640373 283.393066,89.662071 282.289703,88.663765 
	C281.798767,88.979462 281.307831,89.295158 280.816895,89.610855 
	C280.816895,97.586205 280.816895,105.561546 280.816895,114.596436 
	C273.436829,106.831970 266.914093,99.969513 259.896545,92.599113 
M355.208344,51.283928 
	C354.145142,49.669060 353.081909,48.054188 352.016479,46.435944 
	C359.023041,42.085964 361.051544,37.919857 358.986542,32.578281 
	C356.781219,26.873711 350.268066,24.754736 340.361633,26.787310 
	C340.361633,37.295101 340.271973,47.916874 340.508087,58.531399 
	C340.530304,59.530865 342.602905,60.484718 343.723572,61.459751 
	C344.208557,61.145351 344.693542,60.830948 345.178528,60.516548 
	C345.178528,55.834351 345.178528,51.152149 345.178528,46.153214 
	C352.166046,49.901161 352.003448,60.470295 362.438934,61.300419 
	C359.677460,57.466335 357.657990,54.662483 355.208344,51.283928 
M400.655518,119.285782 
	C400.655518,116.226257 400.655518,113.166733 400.655518,109.430710 
	C407.740173,113.261429 407.825531,123.393456 417.903107,124.320656 
	C413.847137,118.599388 410.619507,114.046585 407.385071,109.484146 
	C414.341278,105.310356 416.470001,101.184486 414.614838,95.749611 
	C412.676971,90.072441 406.290527,87.904930 396.361847,89.650513 
	C396.361847,100.996193 396.361847,112.372658 396.361847,123.612663 
	C400.027557,124.781860 400.939636,123.149178 400.655518,119.285782 
M294.836487,115.261002 
	C293.593567,117.958374 292.350647,120.655746 291.084412,123.403702 
	C294.259338,124.810356 296.210083,123.888657 296.983185,120.904793 
	C298.257629,115.985931 301.392273,114.309479 306.210602,115.156769 
	C307.016937,115.298561 307.888428,115.291122 308.697754,115.157921 
	C313.430969,114.378906 316.730927,115.785408 317.992279,120.803139 
	C318.739014,123.773582 320.660248,124.817490 323.960388,123.422066 
	C318.590485,111.711174 313.229034,100.018723 307.523590,87.576073 
	C303.130432,97.099312 299.106384,105.822464 294.836487,115.261002 
M327.409119,45.051788 
	C324.397125,38.515335 321.385132,31.978886 317.977539,24.583914 
	C312.229767,37.164932 306.951141,48.719021 301.664734,60.290195 
	C305.807526,62.226955 307.069489,59.677677 307.854370,57.072079 
	C309.066864,53.046879 311.513550,51.491089 315.610443,52.142647 
	C316.908936,52.349155 318.294037,52.344418 319.594604,52.144348 
	C324.192749,51.436989 327.184509,52.980206 328.456207,57.690372 
	C329.188232,60.401859 330.822754,62.047607 334.273590,60.245750 
	C332.094299,55.370625 329.935760,50.541912 327.409119,45.051788 
M226.902161,100.357483 
	C223.416351,107.987038 219.930542,115.616592 216.436890,123.263321 
	C221.146072,125.348923 221.978088,122.111237 222.843323,119.555313 
	C223.960419,116.255287 225.757858,114.558334 229.405090,115.136185 
	C230.862335,115.367065 232.414780,115.360367 233.875412,115.140167 
	C238.808762,114.396446 241.961365,115.884155 243.416931,121.093849 
	C244.347626,124.424919 244.838791,124.287689 249.055511,123.063927 
	C243.742157,111.487244 238.447418,99.951141 232.769547,87.580261 
	C230.618607,92.227234 228.899551,95.941116 226.902161,100.357483 
M183.001221,31.483671 
	C185.810120,29.136705 188.610107,29.308994 191.713943,31.093536 
	C192.824188,31.731869 194.497360,31.391132 197.203430,31.593924 
	C195.639313,29.503944 195.236923,28.773033 194.659744,28.226810 
	C191.269287,25.018351 186.999649,24.007446 183.227905,26.382149 
	C180.605179,28.033424 178.064835,31.293633 177.420273,34.255615 
	C176.498291,38.492332 180.073746,41.050182 183.702560,42.807056 
	C185.497986,43.676292 187.776535,44.056763 189.033005,45.406403 
	C190.761047,47.262604 192.686844,49.820370 192.704529,52.091755 
	C192.717728,53.786957 190.141342,56.292065 188.187317,57.029133 
	C185.200653,58.155701 182.160583,57.014656 180.848785,53.647709 
	C179.729828,50.775677 178.264343,50.578548 176.135330,52.468071 
	C177.399246,58.413296 181.234833,61.827217 186.623032,61.871479 
	C191.562775,61.912064 195.924149,58.591896 197.342346,53.711227 
	C198.801498,48.689598 196.679565,44.178989 191.391632,41.406002 
	C189.486557,40.406986 187.407364,39.745499 185.437042,38.864201 
	C182.432373,37.520260 180.966599,35.458641 183.001221,31.483671 
M214.390472,60.370274 
	C218.771591,60.516441 223.175674,60.994888 227.525925,60.694286 
	C230.525909,60.486984 231.706268,58.298874 230.307983,55.007389 
	C223.360443,58.425953 216.611374,59.259949 211.010651,52.560925 
	C207.129105,47.918205 206.671082,40.847496 210.152847,35.786888 
	C215.486374,28.034769 222.802490,28.722799 231.027054,31.314024 
	C230.223862,29.167765 229.217499,27.016998 227.623672,26.410820 
	C225.240555,25.504444 222.378845,25.172125 219.835327,25.495901 
	C211.051224,26.614059 205.360306,32.175648 203.643845,40.741871 
	C202.079956,48.546516 205.489914,55.109291 214.390472,60.370274 
M385.163330,93.067749 
	C386.057770,92.398804 386.952179,91.729851 387.846619,91.060913 
	C387.107971,90.400368 386.386047,89.190460 385.627960,89.167343 
	C380.215607,89.002327 374.795837,89.079277 369.021362,89.079277 
	C369.021362,95.396935 369.021332,101.020912 369.021332,106.644897 
	C369.021332,112.373627 369.021362,118.102356 369.021362,124.007538 
	C374.531403,124.007538 379.187927,124.168755 383.820801,123.908531 
	C385.197327,123.831223 386.507324,122.569664 387.847839,121.850746 
	C386.572174,121.232269 385.328888,120.177788 384.014557,120.080269 
	C380.604675,119.827271 377.163513,119.995323 373.708984,119.995323 
	C373.708984,115.299454 373.708984,111.272530 373.708984,106.950188 
	C376.112366,106.950188 378.150818,107.245804 380.060120,106.875542 
	C382.460175,106.410110 385.797882,108.831863 387.147949,104.800041 
	C384.873505,104.175255 382.622284,103.434883 380.315460,102.971260 
	C379.037201,102.714363 377.507263,103.294136 376.379852,102.815651 
	C375.176788,102.305077 373.608765,101.112282 373.420441,100.009010 
	C372.378693,93.905899 373.190247,93.084625 379.257477,93.074692 
	C380.919159,93.071968 382.580841,93.073593 385.163330,93.067749 
M238.906006,58.282585 
	C239.917587,59.188908 240.877045,60.797527 241.950012,60.877182 
	C246.087921,61.184341 250.269470,61.138561 254.417892,60.913528 
	C255.532242,60.853077 256.586914,59.692665 257.668976,59.037189 
	C256.609985,58.357948 255.584839,57.165478 254.485825,57.092976 
	C250.768463,56.847729 247.024902,56.999466 243.447998,56.999466 
	C243.447998,52.174980 243.447998,48.253536 243.447998,43.947319 
	C246.594269,43.947319 249.421417,44.167637 252.191238,43.864269 
	C253.839767,43.683712 255.398117,42.679764 256.997009,42.046001 
	C255.476379,41.340122 254.004761,40.201107 252.424500,40.022358 
	C249.514374,39.693165 246.540375,39.928368 243.376038,39.928368 
	C243.376038,36.431618 243.376038,33.531303 243.376038,30.051506 
	C247.582535,30.051506 251.536819,30.157013 255.475967,29.959820 
	C256.236481,29.921749 257.161285,28.802679 257.548187,27.968069 
	C257.688477,27.665413 256.491669,26.193712 255.881119,26.177937 
	C250.304688,26.033834 244.722992,26.093256 238.899887,26.093256 
	C238.899887,37.030903 238.899887,47.174782 238.906006,58.282585 
M372.960144,36.531548 
	C372.960144,33.237553 372.960144,29.943560 372.960144,26.649565 
	C372.572754,26.300444 372.185364,25.951324 371.797974,25.602203 
	C370.595184,26.515123 368.367798,27.404615 368.346283,28.344549 
	C368.100128,39.108475 368.189606,49.880074 368.189606,60.445053 
	C371.776825,61.682278 373.081696,60.823204 373.004425,57.492760 
	C372.850067,50.839493 372.959595,44.180099 372.960144,36.531548 
z"
            />
            <path
              fill="#5E6D88"
              opacity="1.000000"
              stroke="none"
              d="
M96.925598,20.728363 
	C84.434425,27.544855 78.039162,37.694065 78.014549,51.649658 
	C77.983994,68.978676 77.974953,86.307869 78.019249,103.636826 
	C78.043671,113.190712 74.342209,120.663795 66.271492,125.987915 
	C64.699692,127.024811 63.551361,128.762375 62.347366,130.287033 
	C58.757481,134.833038 53.212978,135.988022 48.624340,133.063950 
	C43.952492,130.086868 42.353283,124.349297 44.846500,119.510078 
	C47.461372,114.434731 53.237854,112.181076 58.415352,115.015121 
	C61.763294,116.847694 63.702515,115.700256 64.804863,113.119637 
	C66.135010,110.005760 67.268982,106.528801 67.315422,103.192680 
	C67.556595,85.867004 67.406738,68.536110 67.426201,51.207077 
	C67.451660,28.533609 84.418243,9.295590 106.311279,7.076556 
	C129.384445,4.737906 149.465775,18.984028 154.450546,41.458099 
	C155.343964,45.486099 155.622955,49.708153 155.694778,53.849125 
	C155.868134,63.843658 155.724945,73.843323 155.762421,83.840721 
	C155.780670,88.708908 155.217102,93.357422 159.128510,97.751999 
	C162.658554,101.718079 160.613327,108.199944 156.165848,111.346077 
	C152.123657,114.205513 146.444031,113.630386 142.957047,110.008575 
	C139.165115,106.070023 139.197586,100.387047 142.532455,95.888123 
	C143.870316,94.083282 144.931061,91.589035 144.972168,89.387184 
	C145.202301,77.061195 145.162659,64.728012 145.063049,52.398510 
	C144.906235,32.985733 132.934464,19.298399 113.725258,17.666904 
	C108.362251,17.211412 102.763725,19.528877 96.925598,20.728363 
z"
            />
            <path
              fill="#5F89FF"
              opacity="1.000000"
              stroke="none"
              d="
M10.196314,110.473999 
	C10.088675,95.699181 10.154062,81.370903 9.876942,67.049255 
	C9.831287,64.689888 8.749668,62.018448 7.335441,60.084599 
	C4.070621,55.620213 4.105460,49.636738 7.970014,46.025787 
	C12.253306,42.023567 18.152077,42.006256 22.378660,45.983509 
	C26.290752,49.664818 26.351849,55.505276 23.052814,60.011997 
	C21.629404,61.956474 20.412964,64.606178 20.403128,66.940994 
	C20.343533,81.089233 20.114927,95.272934 20.992395,109.379501 
	C22.018751,125.879692 36.987194,138.889374 53.772358,138.986908 
	C70.517258,139.084213 85.919518,126.294533 86.937912,109.802170 
	C88.024124,92.211494 87.944023,74.517754 87.626015,56.882477 
	C87.418343,45.366112 89.900673,35.803188 100.575485,29.746628 
	C101.474358,29.236637 101.955414,27.958351 102.581505,27.004782 
	C105.908821,21.937067 112.045197,20.344360 116.959023,23.289114 
	C121.432899,25.970215 123.201057,32.150219 120.848961,36.885086 
	C118.374313,41.866650 112.117805,43.894199 106.752808,41.423889 
	C105.874054,41.019272 105.059738,40.474693 104.164192,39.965542 
	C99.597931,43.089783 98.004982,47.340801 98.020012,52.497814 
	C98.070053,69.656807 98.081810,86.816170 98.026871,103.975143 
	C97.944885,129.582687 80.150169,148.253418 54.707745,149.513290 
	C34.731266,150.502518 14.933697,134.797546 11.006697,114.844513 
	C10.749664,113.538536 10.505240,112.230072 10.196314,110.473999 
z"
            />
            <path
              fill="#638BFF"
              opacity="1.000000"
              stroke="none"
              d="
M135.781967,107.999130 
	C135.781967,112.327576 135.880203,116.159492 135.753586,119.983971 
	C135.635925,123.538193 136.067993,126.533394 138.748611,129.480270 
	C142.314651,133.400497 141.325165,139.508118 137.428909,142.953049 
	C133.215134,146.678726 127.397057,146.661896 123.402000,142.912476 
	C119.384109,139.141632 118.519081,133.010696 122.310204,129.004028 
	C124.838638,126.331818 125.079674,123.654762 125.072701,120.473976 
	C125.031784,101.830101 125.133324,83.185265 124.945366,64.543198 
	C124.923103,62.334938 123.862617,59.826721 122.534409,58.003059 
	C119.192154,53.414101 119.177414,47.691284 122.996216,43.904522 
	C126.851158,40.081917 133.075409,39.799122 137.005981,43.267990 
	C141.504318,47.237938 141.870224,52.621826 138.691269,57.637318 
	C137.145386,60.076271 135.967300,63.208057 135.909332,66.052422 
	C135.627853,79.863197 135.782166,93.682854 135.781967,107.999130 
z"
            />
            <path
              fill="#5C6B86"
              opacity="1.000000"
              stroke="none"
              d="
M40.561348,83.982651 
	C40.668056,90.149017 39.838547,95.669479 44.259071,100.797043 
	C47.571148,104.638870 45.292728,110.956657 40.989384,113.878029 
	C36.763309,116.746941 31.336990,116.175507 27.801563,112.489258 
	C24.169094,108.701805 23.583899,102.677132 27.230692,98.908165 
	C29.603279,96.456093 30.057846,94.024948 30.043146,90.929031 
	C29.954636,72.289444 30.089426,53.648239 29.885281,35.010468 
	C29.859674,32.672741 28.734499,30.022425 27.330124,28.090143 
	C24.133902,23.692465 24.087610,17.557838 27.765043,13.937927 
	C31.802572,9.963553 38.034786,9.806653 42.179535,13.575033 
	C46.222054,17.250467 46.456596,22.875454 43.476036,27.618961 
	C41.934441,30.072380 40.735775,33.207306 40.688225,36.056126 
	C40.424389,51.862438 40.561653,67.675446 40.561348,83.982651 
z"
            />
            <path
              fill="#678FFF"
              opacity="1.000000"
              stroke="none"
              d="
M200.338470,100.275879 
	C202.083084,96.286949 203.656815,92.636948 205.871185,87.501083 
	C208.428772,100.266541 210.707794,111.641541 213.035812,123.261147 
	C210.140823,124.500351 208.416260,124.578842 207.884491,120.812935 
	C207.032104,114.776398 205.748520,108.800735 204.397491,101.438660 
	C200.765030,109.534889 197.622406,116.539330 193.975739,124.667229 
	C190.378357,116.727539 187.172806,109.652634 183.468185,101.476250 
	C182.159653,108.703674 180.897400,114.640030 180.075027,120.636703 
	C179.637100,123.830170 178.321762,124.617851 174.909775,123.445152 
	C177.096390,112.154022 179.285553,100.849739 181.474716,89.545456 
	C181.845749,89.439064 182.216782,89.332672 182.587814,89.226273 
	C186.161285,97.216263 189.734756,105.206245 193.938141,114.604645 
	C196.355560,109.175720 198.261581,104.895264 200.338470,100.275879 
z"
            />
            <path
              fill="#698FFF"
              opacity="1.000000"
              stroke="none"
              d="
M286.106934,45.956699 
	C287.484406,47.609638 288.611816,49.000137 289.739227,50.390636 
	C290.266907,50.164818 290.794617,49.938999 291.322327,49.713181 
	C291.322327,43.030033 291.409332,36.345253 291.284302,29.664444 
	C291.224915,26.492817 292.330780,25.336430 295.704224,26.533964 
	C295.704224,38.014931 295.704224,49.567684 295.704224,62.296001 
	C287.056915,53.266590 278.991058,44.844345 270.083160,35.542843 
	C270.083160,44.537930 270.083160,52.511040 270.083160,60.484146 
	C269.529114,60.829647 268.975037,61.175152 268.420990,61.520653 
	C267.343170,60.391357 265.354919,59.282288 265.329285,58.129620 
	C265.090088,47.361454 265.187561,36.585804 265.187561,27.060143 
	C271.746674,32.973423 278.801758,39.333843 286.106934,45.956699 
z"
            />
            <path
              fill="#678EFF"
              opacity="1.000000"
              stroke="none"
              d="
M416.125336,40.802109 
	C417.169617,51.988266 410.349640,60.634602 399.915375,61.810627 
	C390.635620,62.856529 381.369843,55.820530 380.068481,46.739807 
	C378.553131,36.165802 385.518250,26.949154 396.218231,25.369633 
	C404.986908,24.075207 413.526489,30.546104 416.125336,40.802109 
M411.117981,48.252323 
	C413.423065,39.585102 409.150269,31.542364 401.421997,30.001434 
	C392.835785,28.289438 385.799255,33.173088 384.424225,41.798592 
	C383.449799,47.911057 387.636078,54.811153 393.514832,56.782352 
	C400.481262,59.118263 406.555176,56.401279 411.117981,48.252323 
z"
            />
            <path
              fill="#688FFF"
              opacity="1.000000"
              stroke="none"
              d="
M342.613220,92.977921 
	C335.308624,96.026566 331.584686,101.345604 332.116486,107.443062 
	C332.673126,113.825249 336.937927,118.845222 342.972015,120.220787 
	C349.463013,121.700500 354.968170,118.041565 356.444031,111.288940 
	C353.678101,110.696487 350.895844,110.161461 348.178802,109.395195 
	C347.976044,109.338013 348.240326,107.624672 348.298859,106.454330 
	C352.807129,106.454330 357.063599,106.454330 361.275482,106.454330 
	C362.616180,113.991798 358.623352,120.933517 351.570557,123.653175 
	C343.857422,126.627480 334.720886,123.409531 330.328094,116.171402 
	C325.844238,108.783218 327.383942,98.510345 333.810669,92.935646 
	C340.570740,87.071793 350.230865,86.850235 357.218079,92.298950 
	C359.722748,94.252136 360.651123,95.939377 356.765900,97.765930 
	C353.123474,93.754662 348.556793,91.889320 342.613220,92.977921 
z"
            />
            <path
              fill="#618AFF"
              opacity="1.000000"
              stroke="none"
              d="
M260.143951,92.853088 
	C266.914093,99.969513 273.436829,106.831970 280.816895,114.596436 
	C280.816895,105.561546 280.816895,97.586205 280.816895,89.610855 
	C281.307831,89.295158 281.798767,88.979462 282.289703,88.663765 
	C283.393066,89.662071 285.433716,90.640373 285.455505,91.661758 
	C285.687683,102.541641 285.599731,113.428345 285.599731,123.736877 
	C276.841309,115.880554 268.107910,108.046654 259.152802,100.013885 
	C259.152802,104.538620 259.153168,109.680435 259.152618,114.822250 
	C259.152405,116.817589 259.386169,118.852737 259.058777,120.793053 
	C258.867950,121.924171 257.777344,122.903488 257.091583,123.951103 
	C256.376740,122.941833 255.055099,121.941208 255.040573,120.921944 
	C254.888901,110.287163 254.947083,99.649391 254.947083,87.615227 
	C257.091278,89.774330 258.493896,91.186722 260.143951,92.853088 
z"
            />
            <path
              fill="#648CFF"
              opacity="1.000000"
              stroke="none"
              d="
M355.423462,51.571278 
	C357.657990,54.662483 359.677460,57.466335 362.438934,61.300419 
	C352.003448,60.470295 352.166046,49.901161 345.178528,46.153214 
	C345.178528,51.152149 345.178528,55.834351 345.178528,60.516548 
	C344.693542,60.830948 344.208557,61.145351 343.723572,61.459751 
	C342.602905,60.484718 340.530304,59.530865 340.508087,58.531399 
	C340.271973,47.916874 340.361633,37.295101 340.361633,26.787310 
	C350.268066,24.754736 356.781219,26.873711 358.986542,32.578281 
	C361.051544,37.919857 359.023041,42.085964 352.016479,46.435944 
	C353.081909,48.054188 354.145142,49.669060 355.423462,51.571278 
M344.981018,40.068993 
	C345.988251,40.932770 347.082886,42.609142 347.986786,42.511959 
	C351.017273,42.186169 354.849335,41.968945 354.987244,37.814095 
	C355.057648,35.693333 354.069824,32.828781 352.525543,31.555899 
	C350.873718,30.194382 348.004089,30.310345 344.976532,29.625431 
	C344.976532,33.316544 344.976532,36.246853 344.981018,40.068993 
z"
            />
            <path
              fill="#648CFF"
              opacity="1.000000"
              stroke="none"
              d="
M400.654968,119.737190 
	C400.939636,123.149178 400.027557,124.781860 396.361847,123.612663 
	C396.361847,112.372658 396.361847,100.996193 396.361847,89.650513 
	C406.290527,87.904930 412.676971,90.072441 414.614838,95.749611 
	C416.470001,101.184486 414.341278,105.310356 407.385071,109.484146 
	C410.619507,114.046585 413.847137,118.599388 417.903107,124.320656 
	C407.825531,123.393456 407.740173,113.261429 400.655518,109.430710 
	C400.655518,113.166733 400.655518,116.226257 400.654968,119.737190 
M400.253815,102.923660 
	C400.243958,105.881126 402.324188,106.430405 404.250214,105.475250 
	C406.678070,104.271263 409.456909,102.652687 410.616119,100.442818 
	C411.288879,99.160393 409.627350,95.609459 408.024841,94.452515 
	C406.189362,93.127335 403.255280,93.323830 400.244293,92.754402 
	C400.244293,96.492271 400.244293,99.275093 400.253815,102.923660 
z"
            />
            <path
              fill="#678EFF"
              opacity="1.000000"
              stroke="none"
              d="
M294.959412,114.903313 
	C299.106384,105.822464 303.130432,97.099312 307.523590,87.576073 
	C313.229034,100.018723 318.590485,111.711174 323.960388,123.422066 
	C320.660248,124.817490 318.739014,123.773582 317.992279,120.803139 
	C316.730927,115.785408 313.430969,114.378906 308.697754,115.157921 
	C307.888428,115.291122 307.016937,115.298561 306.210602,115.156769 
	C301.392273,114.309479 298.257629,115.985931 296.983185,120.904793 
	C296.210083,123.888657 294.259338,124.810356 291.084412,123.403702 
	C292.350647,120.655746 293.593567,117.958374 294.959412,114.903313 
M302.171844,109.943420 
	C305.637695,110.463028 309.103577,110.982635 313.808807,111.688057 
	C311.383667,106.228714 309.609406,102.234642 307.501526,97.489525 
	C305.547638,101.907234 303.945435,105.529701 302.171844,109.943420 
z"
            />
            <path
              fill="#668EFF"
              opacity="1.000000"
              stroke="none"
              d="
M327.593170,45.382496 
	C329.935760,50.541912 332.094299,55.370625 334.273590,60.245750 
	C330.822754,62.047607 329.188232,60.401859 328.456207,57.690372 
	C327.184509,52.980206 324.192749,51.436989 319.594604,52.144348 
	C318.294037,52.344418 316.908936,52.349155 315.610443,52.142647 
	C311.513550,51.491089 309.066864,53.046879 307.854370,57.072079 
	C307.069489,59.677677 305.807526,62.226955 301.664734,60.290195 
	C306.951141,48.719021 312.229767,37.164932 317.977539,24.583914 
	C321.385132,31.978886 324.397125,38.515335 327.593170,45.382496 
M318.810455,36.073250 
	C318.397797,35.940952 317.643188,35.651012 317.621155,35.698799 
	C315.802643,39.643623 314.041016,43.614685 312.115692,47.942574 
	C316.407806,47.942574 319.855011,47.942574 323.887268,47.942574 
	C322.175354,43.960892 320.619537,40.342297 318.810455,36.073250 
z"
            />
            <path
              fill="#668DFF"
              opacity="1.000000"
              stroke="none"
              d="
M227.041321,100.006241 
	C228.899551,95.941116 230.618607,92.227234 232.769547,87.580261 
	C238.447418,99.951141 243.742157,111.487244 249.055511,123.063927 
	C244.838791,124.287689 244.347626,124.424919 243.416931,121.093849 
	C241.961365,115.884155 238.808762,114.396446 233.875412,115.140167 
	C232.414780,115.360367 230.862335,115.367065 229.405090,115.136185 
	C225.757858,114.558334 223.960419,116.255287 222.843323,119.555313 
	C221.978088,122.111237 221.146072,125.348923 216.436890,123.263321 
	C219.930542,115.616592 223.416351,107.987038 227.041321,100.006241 
M233.940033,99.875687 
	C233.213699,99.792091 231.943420,99.450134 231.842133,99.663406 
	C230.149704,103.226692 228.603439,106.859413 226.935257,110.681473 
	C230.948929,110.681473 234.432281,110.681473 238.546661,110.681473 
	C236.919556,106.894630 235.555389,103.719727 233.940033,99.875687 
z"
            />
            <path
              fill="#6990FF"
              opacity="1.000000"
              stroke="none"
              d="
M182.742371,31.799198 
	C180.966599,35.458641 182.432373,37.520260 185.437042,38.864201 
	C187.407364,39.745499 189.486557,40.406986 191.391632,41.406002 
	C196.679565,44.178989 198.801498,48.689598 197.342346,53.711227 
	C195.924149,58.591896 191.562775,61.912064 186.623032,61.871479 
	C181.234833,61.827217 177.399246,58.413296 176.135330,52.468071 
	C178.264343,50.578548 179.729828,50.775677 180.848785,53.647709 
	C182.160583,57.014656 185.200653,58.155701 188.187317,57.029133 
	C190.141342,56.292065 192.717728,53.786957 192.704529,52.091755 
	C192.686844,49.820370 190.761047,47.262604 189.033005,45.406403 
	C187.776535,44.056763 185.497986,43.676292 183.702560,42.807056 
	C180.073746,41.050182 176.498291,38.492332 177.420273,34.255615 
	C178.064835,31.293633 180.605179,28.033424 183.227905,26.382149 
	C186.999649,24.007446 191.269287,25.018351 194.659744,28.226810 
	C195.236923,28.773033 195.639313,29.503944 197.203430,31.593924 
	C194.497360,31.391132 192.824188,31.731869 191.713943,31.093536 
	C188.610107,29.308994 185.810120,29.136705 182.742371,31.799198 
z"
            />
            <path
              fill="#688FFF"
              opacity="1.000000"
              stroke="none"
              d="
M214.035812,60.214924 
	C205.489914,55.109291 202.079956,48.546516 203.643845,40.741871 
	C205.360306,32.175648 211.051224,26.614059 219.835327,25.495901 
	C222.378845,25.172125 225.240555,25.504444 227.623672,26.410820 
	C229.217499,27.016998 230.223862,29.167765 231.027054,31.314024 
	C222.802490,28.722799 215.486374,28.034769 210.152847,35.786888 
	C206.671082,40.847496 207.129105,47.918205 211.010651,52.560925 
	C216.611374,59.259949 223.360443,58.425953 230.307983,55.007389 
	C231.706268,58.298874 230.525909,60.486984 227.525925,60.694286 
	C223.175674,60.994888 218.771591,60.516441 214.035812,60.214924 
z"
            />
            <path
              fill="#628BFF"
              opacity="1.000000"
              stroke="none"
              d="
M384.702942,93.070480 
	C382.580841,93.073593 380.919159,93.071968 379.257477,93.074692 
	C373.190247,93.084625 372.378693,93.905899 373.420441,100.009010 
	C373.608765,101.112282 375.176788,102.305077 376.379852,102.815651 
	C377.507263,103.294136 379.037201,102.714363 380.315460,102.971260 
	C382.622284,103.434883 384.873505,104.175255 387.147949,104.800041 
	C385.797882,108.831863 382.460175,106.410110 380.060120,106.875542 
	C378.150818,107.245804 376.112366,106.950188 373.708984,106.950188 
	C373.708984,111.272530 373.708984,115.299454 373.708984,119.995323 
	C377.163513,119.995323 380.604675,119.827271 384.014557,120.080269 
	C385.328888,120.177788 386.572174,121.232269 387.847839,121.850739 
	C386.507324,122.569664 385.197327,123.831223 383.820801,123.908531 
	C379.187927,124.168755 374.531403,124.007538 369.021362,124.007538 
	C369.021362,118.102356 369.021332,112.373627 369.021332,106.644897 
	C369.021332,101.020912 369.021362,95.396935 369.021362,89.079277 
	C374.795837,89.079277 380.215607,89.002327 385.627960,89.167343 
	C386.386047,89.190460 387.107971,90.400368 387.846619,91.060913 
	C386.952179,91.729851 386.057770,92.398804 384.702942,93.070480 
z"
            />
            <path
              fill="#618AFF"
              opacity="1.000000"
              stroke="none"
              d="
M238.902954,57.800621 
	C238.899887,47.174782 238.899887,37.030903 238.899887,26.093256 
	C244.722992,26.093256 250.304688,26.033834 255.881119,26.177937 
	C256.491669,26.193712 257.688477,27.665413 257.548187,27.968069 
	C257.161285,28.802679 256.236481,29.921749 255.475967,29.959820 
	C251.536819,30.157013 247.582535,30.051506 243.376038,30.051506 
	C243.376038,33.531303 243.376038,36.431618 243.376038,39.928368 
	C246.540375,39.928368 249.514374,39.693165 252.424500,40.022358 
	C254.004761,40.201107 255.476379,41.340122 256.997009,42.045998 
	C255.398117,42.679764 253.839767,43.683712 252.191238,43.864269 
	C249.421417,44.167637 246.594269,43.947319 243.447998,43.947319 
	C243.447998,48.253536 243.447998,52.174980 243.447998,56.999466 
	C247.024902,56.999466 250.768463,56.847729 254.485825,57.092976 
	C255.584839,57.165478 256.609985,58.357948 257.668976,59.037193 
	C256.586914,59.692665 255.532242,60.853077 254.417892,60.913528 
	C250.269470,61.138561 246.087921,61.184341 241.950012,60.877182 
	C240.877045,60.797527 239.917587,59.188908 238.902954,57.800621 
z"
            />
            <path
              fill="#7196FF"
              opacity="1.000000"
              stroke="none"
              d="
M372.960144,37.027393 
	C372.959595,44.180099 372.850067,50.839493 373.004425,57.492760 
	C373.081696,60.823204 371.776825,61.682278 368.189606,60.445053 
	C368.189606,49.880074 368.100128,39.108475 368.346283,28.344549 
	C368.367798,27.404615 370.595184,26.515123 371.797974,25.602203 
	C372.185364,25.951324 372.572754,26.300444 372.960144,26.649565 
	C372.960144,29.943560 372.960144,33.237553 372.960144,37.027393 
z"
            />
            <path
              fill="#FCFDFF"
              opacity="1.000000"
              stroke="none"
              d="
M411.008270,48.629303 
	C406.555176,56.401279 400.481262,59.118263 393.514832,56.782352 
	C387.636078,54.811153 383.449799,47.911057 384.424225,41.798592 
	C385.799255,33.173088 392.835785,28.289438 401.421997,30.001434 
	C409.150269,31.542364 413.423065,39.585102 411.008270,48.629303 
z"
            />
            <path
              fill="#FAFBFF"
              opacity="1.000000"
              stroke="none"
              d="
M344.978760,39.623077 
	C344.976532,36.246853 344.976532,33.316544 344.976532,29.625431 
	C348.004089,30.310345 350.873718,30.194382 352.525543,31.555899 
	C354.069824,32.828781 355.057648,35.693333 354.987244,37.814095 
	C354.849335,41.968945 351.017273,42.186169 347.986786,42.511959 
	C347.082886,42.609142 345.988251,40.932770 344.978760,39.623077 
z"
            />
            <path
              fill="#F3F6FF"
              opacity="1.000000"
              stroke="none"
              d="
M400.249054,102.490784 
	C400.244293,99.275093 400.244293,96.492271 400.244293,92.754402 
	C403.255280,93.323830 406.189362,93.127335 408.024841,94.452515 
	C409.627350,95.609459 411.288879,99.160393 410.616119,100.442818 
	C409.456909,102.652687 406.678070,104.271263 404.250214,105.475250 
	C402.324188,106.430405 400.243958,105.881126 400.249054,102.490784 
z"
            />
            <path
              fill="#F9FBFF"
              opacity="1.000000"
              stroke="none"
              d="
M302.257568,109.547791 
	C303.945435,105.529701 305.547638,101.907234 307.501526,97.489525 
	C309.609406,102.234642 311.383667,106.228714 313.808807,111.688057 
	C309.103577,110.982635 305.637695,110.463028 302.257568,109.547791 
z"
            />
            <path
              fill="#F8FAFF"
              opacity="1.000000"
              stroke="none"
              d="
M318.937073,36.398476 
	C320.619537,40.342297 322.175354,43.960892 323.887268,47.942574 
	C319.855011,47.942574 316.407806,47.942574 312.115692,47.942574 
	C314.041016,43.614685 315.802643,39.643623 317.621155,35.698799 
	C317.643188,35.651012 318.397797,35.940952 318.937073,36.398476 
z"
            />
            <path
              fill="#F8F9FF"
              opacity="1.000000"
              stroke="none"
              d="
M234.065628,100.210251 
	C235.555389,103.719727 236.919556,106.894630 238.546661,110.681473 
	C234.432281,110.681473 230.948929,110.681473 226.935257,110.681473 
	C228.603439,106.859413 230.149704,103.226692 231.842133,99.663406 
	C231.943420,99.450134 233.213699,99.792091 234.065628,100.210251 
z"
            />
          </svg>

          <input
            id={"LoginScreen-Input"}
            type="email"
            name="email"
            onChange={(event) => {
              this.props.stateHandler.loginFormEmailChange(
                event.target.value,
                this.props.updateState
              );
            }}
            placeholder="E-mailadres"
          />
          <input
            id={"LoginScreen-Input"}
            type="password"
            name="password"
            onChange={(event) => {
              this.props.stateHandler.loginFormPasswordChange(
                event.target.value,
                this.props.updateState
              );
            }}
            placeholder="Wachtwoord"
          />
          <Button
            onClick={() => {
              this.props.stateHandler.login(this.props.updateState);
            }}
          >
            Login
          </Button>

          <div id={"LoginScreen-MoreInfo"}>
            Voor meer informatie, neem contact op via{" "}
            <a id={"LoginScreen-Link"} href="mailto:info@stratopo.nl">
              info@stratopo.nl
            </a>
          </div>
        </div>
      </div>
    );
  }
}

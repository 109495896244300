import React from "react";
import { MdClose } from "react-icons/md";
import { AppStateHandler } from "src/AppStateHandler";
import DropDown from "src/components/DropDown";
import {
  HousingTypeEnumToDropDown,
  HousingTypeEnumToIndex,
  IndexToHousingTypeEnum,
} from "src/helperFunctions";
import MenuTitle from "./MenuTitle";

import { AppStateType } from "src/Types";
import { HousingTypeEnum } from "../client/lib/models";
import "./EditHousingPlanAreaPopUp.css";
import MainButton from "./MainButton";
import { TextInput, TextInputType } from "./TextInput";
interface EditHousingPlanAreaPopUpProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class EditHousingPlanAreaPopUp extends React.Component<EditHousingPlanAreaPopUpProps> {
  render() {
    return (
      <div id="EditHousingPlanAreaPopUp">
        <div id="EditHousingPlanAreaPopUp_Box">
          <div id="EditHousingPlanAreaPopUp_ContentWrapper">
            <MenuTitle
              title="Woongebied bewerken"
              onClick={() => {
                this.props.state.EditHousingPlanAreasScreen.EditHousingPlanAreaPopUpVisable =
                  false;
                this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea =
                  null;
                this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas =
                  [];
                this.props.state.EditHousingPlanAreasScreen.EditHousingPlanAreaPopUpVisable =
                  false;

                this.props.stateHandler.load_HousingPlanAreas(
                  this.props.updateState
                );
                this.props.updateState(this.props.state);
              }}
              button={true}
              button_content={<MdClose size={25} />}
              transparentButtonBackground={true}
            />

            <div id="EditHousingPlanAreaPopUp_Section">
              <div className="EditHousingPlanAreaPopUp_Question">Naam:</div>
              <TextInput
                value={`${
                  this.props.state.EditHousingPlanAreasScreen
                    .EditHousingPlanArea?.name || ""
                }`}
                onChange={(name) => {
                  if (
                    this.props.state.EditHousingPlanAreasScreen
                      .EditHousingPlanArea
                  ) {
                    this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea.name =
                      name;
                  }
                  this.props.updateState(this.props.state);
                }}
                placeholderColor={"#BBB"}
                placeholder={"Naam"}
                type={TextInputType.TextSpacesNumbersLines}
              ></TextInput>
              <div className="EditHousingPlanAreaPopUp_Question">
                Aantal huizen:
              </div>
              <TextInput
                value={`${this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea?.number_of_houses}`}
                // style={inputStyling}
                onChange={(number_of_houses) => {
                  if (
                    this.props.state.EditHousingPlanAreasScreen
                      .EditHousingPlanArea
                  ) {
                    this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea.number_of_houses =
                      parseInt(number_of_houses, 10);
                  }
                  this.props.updateState(this.props.state);
                }}
                placeholderColor={"#BBB"}
                placeholder={"Aantal huizen"}
                type={TextInputType.UnsignedInteger}
              ></TextInput>
              <div className="EditHousingPlanAreaPopUp_Question">
                Woningtype
              </div>
              <DropDown
                list={HousingTypeEnumToDropDown()}
                onChange={(newKey) => {
                  if (
                    this.props.state.EditHousingPlanAreasScreen
                      .EditHousingPlanArea
                  ) {
                    this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea.housing_type =
                      IndexToHousingTypeEnum(newKey);
                    this.props.updateState(this.props.state);
                  }
                }}
                selectedKey={HousingTypeEnumToIndex(
                  this.props.state.EditHousingPlanAreasScreen
                    .EditHousingPlanArea?.housing_type ||
                    HousingTypeEnum.Apartment
                )}
              ></DropDown>
            </div>

            <div id="EditHousingPlanAreaPopUp_SubmitButtonHolder">
              <MainButton
                // style={submitReportButton}
                // hoverStyle={submitReportButtonHover}
                onClick={() => {
                  this.props.stateHandler.updateHousingPlanArea(
                    this.props.state.EditHousingPlanAreasScreen
                      .EditHousingPlanArea?.pk || 0,
                    this.props.updateState
                  );

                  this.props.updateState(this.props.state);
                }}
                className="MainBtn OK"
              >
                Opslaan
              </MainButton>
              <MainButton
                // style={submitReportButton}
                // hoverStyle={submitReportButtonHover}
                onClick={() => {
                  this.props.stateHandler.deleteHousingPlanArea(
                    this.props.state.EditHousingPlanAreasScreen
                      .EditHousingPlanArea?.pk || 0,
                    this.props.updateState
                  );
                  this.props.state.EditHousingPlanAreasScreen.EditHousingPlanAreaPopUpVisable =
                    false;
                  this.props.updateState(this.props.state);
                }}
                className="MainBtn Danger"
              >
                Delete
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.10.10.10.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopologyBoundingBox
 */
export interface TopologyBoundingBox {
    /**
     * 
     * @type {number}
     * @memberof TopologyBoundingBox
     */
    xMin: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyBoundingBox
     */
    yMin: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyBoundingBox
     */
    xMax: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyBoundingBox
     */
    yMax: number;
}

/**
 * Check if a given object implements the TopologyBoundingBox interface.
 */
export function instanceOfTopologyBoundingBox(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "xMin" in value;
    isInstance = isInstance && "yMin" in value;
    isInstance = isInstance && "xMax" in value;
    isInstance = isInstance && "yMax" in value;

    return isInstance;
}

export function TopologyBoundingBoxFromJSON(json: any): TopologyBoundingBox {
    return TopologyBoundingBoxFromJSONTyped(json, false);
}

export function TopologyBoundingBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyBoundingBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'xMin': json['x_min'],
        'yMin': json['y_min'],
        'xMax': json['x_max'],
        'yMax': json['y_max'],
    };
}

export function TopologyBoundingBoxToJSON(value?: TopologyBoundingBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x_min': value.xMin,
        'y_min': value.yMin,
        'x_max': value.xMax,
        'y_max': value.yMax,
    };
}


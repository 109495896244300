import { LatLng } from "leaflet";
import { AppStateType, DrawMode, PopUpState, ScreenState } from "./Types";

import {
  CategoryEnum,
  HousingTypeEnum,
  ScenarioTypeEnum,
  TypeEnum,
} from "./client/lib/models";
export const initialAppState: AppStateType = {
  alerts: [],
  loggedIn: false,
  screenState: ScreenState.ScenarioResult,
  projects: [],
  selectedProject: null,
  selectedScenario: null,
  auth: {
    email: null,
    password: null,
    accessToken: null,
  },
  newProject: {
    form_stage: 1,
    name: null,
    projectArea: {
      coordinates: [],
      mousePosition: null,
      areaClosed: false,
    },
    InitSenarioTypes: [],
    selectedDestinationGroupList: [],
    destinationGroupList: [],
    topologyList: [],
    selectedTopology: null,
  },
  project: {
    name: null,
    typescenario: "",
    sourcedataDate: "",
    createdDate: "",
  },
  scenarioScreen: {
    name: null,
    category: null,
    scenarioType: null,
    scenarioTypeInDutch: "",
    latLng: new LatLng(52.3676, 4.9041),
    calculationFinished: false,
    upToDate: false,
    resultBlocks: [],
  },
  newScenario: {
    name: null,
    category: CategoryEnum.CurrentScenario,
    scenarioType: ScenarioTypeEnum.AccessibilityInView,
  },
  current_user: {
    name: "",
    email: "",
    is_staff: false,
    center_area: null,
  },
  editNetwork: {
    selectedTopology: {
      id: null,
      roads: [],
      vertices: [],
      boundingBox: null,
    },
    map: {
      zoom: 16.0,
      center_lat: 52.0898,
      center_lng: 5.10978,
      x_min: null,
      y_min: null,
      x_max: null,
      y_max: null,
      update_map: false,
    },
    drawMode: DrawMode.Off,
    editedRoads: [],
    roadsToUpgrade: {
      gids: new Set<number>(),
      speed: "",
      reverseSpeed: "",
    },
    editedVertices: [],
    editSpeedPopUpVisable: false,
    roadInfo: [],
  },
  newAnalysisLayer: {
    id: 0,
    name: "",
    destinations: [],
    type: TypeEnum.Citycenter,
  },
  customAnalysisLayers: [],

  popUpState: PopUpState.Hidden,
  Legend: [],
  EditHousingPlanAreasScreen: {
    NewHousingPlanAreaPopUpVisable: false,
    EditHousingPlanAreaPopUpVisable: false,
    SelectedHousingPlanArea: null,
    newHousingPlanArea: {
      pk: 0,
      name: "",
      coordinates: [],
      mousePosition: null,
      areaClosed: false,
      number_of_houses: 0,
      housing_type: HousingTypeEnum.Apartment,
    },
    HousingPlanAreas: [],
    EditHousingPlanArea: null,
  },
  newUser: {
    email: null,
    password: null,
    area_id: null,
  },
  listUsers: [],

  userLogsPopUpVisable: false,
  userLogs: [],
};
export const initialAppStateString: string = JSON.stringify(initialAppState);

import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { AuthUsersCreateRequest } from "../../client/lib/apis/UsersApi";
export class AdminStateHandler {
  // Admin screen
  initAdminScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.AdminScreen;
    this.state.newUser.email = "";
    this.state.newUser.password = "";
    this.state.newUser.area_id = null;
    this.state.listUsers = [];
    this.state.userLogs = [];
    this.loadUsers(updateState);
    updateState(this.state);
  }

  updateNewUser(
    this: AppStateHandler,
    newUser: {
      email?: string;
      password?: string;
      area_id?: number;
    },
    updateState: (newState: AppStateType) => void
  ) {
    this.state.userLogsPopUpVisable = false;
    if (newUser.email) this.state.newUser.email = newUser.email;
    if (newUser.area_id) this.state.newUser.area_id = newUser.area_id;
    if (newUser.password) this.state.newUser.password = newUser.password;
    updateState(this.state);
  }

  createNewUser(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    const authUsersCreateRequest: AuthUsersCreateRequest = {
      user: {
        id: 0,
        email: this.state.newUser.email || "",
        password: this.state.newUser.password || "",
        area: this.state.newUser.area_id || 0,
        isActive: true,
      },
    };
    this.usersApi
      .authUsersCreate(authUsersCreateRequest)
      .then(() => {
        this.initAdminScreen(updateState);
      })
      .catch((error) => {
        console.log("Error while creating new user:", error);
      });
    updateState(this.state);
  }

  loadUsers(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.usersApi
      .authUsersList()
      .then((response) => {
        this.state.listUsers = response;
        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while loading users:", error);
      });
  }

  loadUserlogs(
    this: AppStateHandler,
    user_id: number,

    updateState: (newState: AppStateType) => void
  ) {
    if (!user_id) {
      return;
    }
    this.usersApi
      .apiUsersLogList({ id: user_id })
      .then((response) => {
        this.state.userLogs = response;
        this.state.userLogsPopUpVisable = true;
        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while loading userlogs:", error);
      });
  }
}

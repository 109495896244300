/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.10.10.10.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectGeoJsonPolygon } from './ProjectGeoJsonPolygon';
import {
    ProjectGeoJsonPolygonFromJSON,
    ProjectGeoJsonPolygonFromJSONTyped,
    ProjectGeoJsonPolygonToJSON,
} from './ProjectGeoJsonPolygon';

/**
 * 
 * @export
 * @interface ProjectCreateRequest
 */
export interface ProjectCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectCreateRequest
     */
    destinationGroups: Array<number>;
    /**
     * 
     * @type {ProjectGeoJsonPolygon}
     * @memberof ProjectCreateRequest
     */
    area: ProjectGeoJsonPolygon;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    baseTopology: number;
}

/**
 * Check if a given object implements the ProjectCreateRequest interface.
 */
export function instanceOfProjectCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "destinationGroups" in value;
    isInstance = isInstance && "area" in value;
    isInstance = isInstance && "baseTopology" in value;

    return isInstance;
}

export function ProjectCreateRequestFromJSON(json: any): ProjectCreateRequest {
    return ProjectCreateRequestFromJSONTyped(json, false);
}

export function ProjectCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'destinationGroups': json['destination_groups'],
        'area': ProjectGeoJsonPolygonFromJSON(json['area']),
        'baseTopology': json['base_topology'],
    };
}

export function ProjectCreateRequestToJSON(value?: ProjectCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'destination_groups': value.destinationGroups,
        'area': ProjectGeoJsonPolygonToJSON(value.area),
        'base_topology': value.baseTopology,
    };
}


import React from "react";
import { MdDone } from "react-icons/md";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import MainButton from "../../components/MainButton";
import SectionTitle from "../../components/SectionTitle";
import SelectPointsMap from "../../components/SelectPointsMap";
import { TextInput, TextInputType } from "../../components/TextInput";

import DropDown from "src/components/DropDown";
import {
  IndexToTravelTimeDecayTypeEnum,
  TravelTimeDecayTypeEnumToDropDown,
  TravelTimeDecayTypeEnumToIndex,
} from "src/helperFunctions";
import "./CreateCustomAnalysisLayersScreen.css";
interface CreateCustomAnalysisLayersScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class CreateCustomAnalysisLayersScreen extends React.Component<CreateCustomAnalysisLayersScreenProps> {
  render() {
    return (
      <div id="CreateCustomAnalysisLayersScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="Nieuwe bestemmingenlaag" />
            </li>
            <li>
              <MainButton
                onClick={() => {
                  this.props.stateHandler.saveAnalysisLayer(
                    this.props.updateState
                  );
                }}
                icon={<MdDone size={12} />}
                className="MainBtn OK"
              >
                Opslaan
              </MainButton>
            </li>
          </ul>
        </div>

        <div id="CreateCustomAnalysisLayersScreen-ContentHolder">
          <SectionTitle subtitle={true}>1. Bestemmingenlaag naam</SectionTitle>

          <TextInput
            type={TextInputType.TextSpacesNumbersLines}
            value={this.props.state.newAnalysisLayer.name}
            onChange={(newValue) => {
              this.props.state.newAnalysisLayer.name = newValue;
              this.props.updateState(this.props.state);
            }}
            placeholder="Bestemmingenlaag naam"
          ></TextInput>
          <SectionTitle subtitle={true}>2. Selecteer punten</SectionTitle>
          <SelectPointsMap
            state={this.props.state}
            stateHandler={this.props.stateHandler}
            updateState={this.props.updateState}
          />
          <SectionTitle subtitle={true}>3. Bestemming type</SectionTitle>
          <p>
            Kies een bestemming type waar uw bestemmingen het meest op lijken
            wat betreft reistijd-bereidheid.
          </p>
          <DropDown
            list={TravelTimeDecayTypeEnumToDropDown()}
            onChange={(newKey) => {
              this.props.state.newAnalysisLayer.type =
                IndexToTravelTimeDecayTypeEnum(newKey);
              this.props.updateState(this.props.state);
            }}
            selectedKey={TravelTimeDecayTypeEnumToIndex(
              this.props.state.newAnalysisLayer.type
            )}
          ></DropDown>
        </div>
      </div>
    );
  }
}

import { LatLng, LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import {
  MapContainer,
  Polygon,
  Polyline,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType } from "src/Types";
import { HousingTypeEnum } from "../client/lib/models";
import "./DrawHousingPlanAreasMap.css";

interface DrawHousingPlanAreasMapProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class DrawHousingPlanAreasMap extends React.Component<DrawHousingPlanAreasMapProps> {
  center: LatLng = this.props.state.scenarioScreen.latLng;

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 14;

  calcDistance(pointA: LatLng, pointB: LatLng): number {
    return Math.sqrt(
      Math.pow(pointA.lat - pointB.lat, 2) +
        Math.pow(pointA.lng - pointB.lng, 2)
    );
  }

  AreaPolygon = () => {
    useMapEvents({
      click: (event) => {
        const { lat, lng } = event.latlng;
        const clickedPoint = new LatLng(lat, lng);

        const polygons =
          this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas || [];

        // Check if the clicked point is inside any existing polygons
        const isInsideAnyPolygon = polygons.some((polygon) => {
          const bounds = new LatLngBounds(
            polygon.coordinates.map((coord) => [coord.lat, coord.lng])
          );
          if (bounds.contains(clickedPoint)) {
            // Perform a more accurate point-in-polygon check
            return isPointInPolygon(clickedPoint, polygon.coordinates);
          }
          return false;
        });

        if (isInsideAnyPolygon) {
          // Do nothing if the click is inside any polygon
          return;
        }

        if (polygons.length > 0) {
          const currentPolygon = polygons[polygons.length - 1];
          const coordinates = currentPolygon.coordinates;
          const areaClosed = currentPolygon.areaClosed;

          if (!areaClosed && coordinates.length > 0) {
            // Check if the clicked point is close to the first point
            if (
              this.calcDistance(coordinates[0], new LatLng(lat, lng)) <=
              0.0001 + 0.00005 * Math.pow(event.target._zoom - this.maxZoom, 2)
            ) {
              if (coordinates.length === 1) {
                // Reset the drawing if there are only two points
                currentPolygon.coordinates = [];
                currentPolygon.areaClosed = false;
              } else {
                // Close the shape
                currentPolygon.coordinates.push(coordinates[0]);
                currentPolygon.areaClosed = true;
                this.props.state.EditHousingPlanAreasScreen.NewHousingPlanAreaPopUpVisable =
                  true;
                this.props.state.EditHousingPlanAreasScreen.newHousingPlanArea =
                  currentPolygon;

                this.props.updateState(this.props.state);
              }
            } else {
              // Add one point
              currentPolygon.coordinates.push(new LatLng(lat, lng));
            }
          } else {
            // Add the first point or start a new polygon if the previous one is closed
            polygons.push({
              pk: 0,
              name: "",
              coordinates: [new LatLng(lat, lng)],
              mousePosition: null,
              areaClosed: false,
              number_of_houses: 0,
              housing_type: HousingTypeEnum.Apartment,
            });
          }
        } else {
          // Add the first point of the first polygon
          polygons.push({
            pk: 0,
            name: "",
            coordinates: [new LatLng(lat, lng)],
            mousePosition: null,
            areaClosed: false,
            number_of_houses: 0,
            housing_type: HousingTypeEnum.Apartment,
          });
        }

        this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas = polygons;
        this.props.updateState(this.props.state);
      },
      mousemove: (event) => {
        // Track the mouse to show where the next point will be
        const { lat, lng } = event.latlng;
        const polygons =
          this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas || [];

        if (polygons.length > 0) {
          const currentPolygon = polygons[polygons.length - 1];
          if (!currentPolygon.areaClosed) {
            currentPolygon.mousePosition = new LatLng(lat, lng);
            this.props.updateState(this.props.state);
          }
        }
      },
    });

    const polygonOptions = { color: "#5d87ff" };

    const isPointInPolygon = (point: LatLng, polygon: LatLng[]): boolean => {
      let inside = false;

      // Iterate through all the edges of the polygon
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lng; // x-coordinate of the current vertex
        const yi = polygon[i].lat; // y-coordinate of the current vertex
        const xj = polygon[j].lng; // x-coordinate of the next vertex
        const yj = polygon[j].lat; // y-coordinate of the next vertex

        // Check if the point lies on the same vertical line as the edge
        const intersect =
          yi > point.lat !== yj > point.lat &&
          point.lng < ((xj - xi) * (point.lat - yi)) / (yj - yi) + xi;

        // If the point intersects with the edge, toggle the inside flag
        if (intersect) {
          inside = !inside;
        }
      }

      return inside;
    };

    const handlePolygonClick = (pk: number) => {
      this.props.state.EditHousingPlanAreasScreen.EditHousingPlanArea =
        this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas.filter(
          (polygon) => polygon.pk === pk
        )[0];
      this.props.state.EditHousingPlanAreasScreen.EditHousingPlanAreaPopUpVisable =
        true;

      this.props.updateState(this.props.state);
    };

    return (
      <>
        {this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas.map(
          (polygon, index) =>
            polygon.areaClosed && polygon.coordinates.length > 0 ? (
              <Polygon
                key={`polygon-${index}`}
                pathOptions={polygonOptions}
                positions={polygon.coordinates}
                eventHandlers={{
                  click: () => {
                    if (polygon.areaClosed) {
                      handlePolygonClick(polygon.pk);
                    }
                  },
                }}
              />
            ) : polygon.mousePosition && polygon.coordinates.length > 0 ? (
              <Polyline
                key={`polyline-${index}`}
                pathOptions={polygonOptions}
                positions={[...polygon.coordinates, polygon.mousePosition]}
              />
            ) : null
        )}
      </>
    );
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        id={"DrawHousingPlanAreasMap"}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <this.AreaPolygon />
      </MapContainer>
    );
  }
}

import React from "react";
import { BiNetworkChart } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { TbReload } from "react-icons/tb";
import DrawHousingPlanAreasMap from "src/components/DrawHousingPlanAreasMap";
import MainButton from "src/components/MainButton";

import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import EditHousingPlanAreaPopUp from "../../components/EditHousingPlanAreaPopUp";
import NewHousingPlanAreaPopUp from "../../components/NewHousingPlanAreaPopUp";
import { AppStateType } from "../../Types";
import "./EditHousingPlanAreasScreen.css";
interface EditHousingPlanAreasScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class EditHousingPlanAreasScreen extends React.Component<EditHousingPlanAreasScreenProps> {
  render() {
    return (
      <>
        {(() => {
          if (
            this.props.state.EditHousingPlanAreasScreen
              .NewHousingPlanAreaPopUpVisable
          ) {
            return (
              <NewHousingPlanAreaPopUp
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></NewHousingPlanAreaPopUp>
            );
          }
        })()}
        {(() => {
          if (
            this.props.state.EditHousingPlanAreasScreen
              .EditHousingPlanAreaPopUpVisable
          ) {
            return (
              <EditHousingPlanAreaPopUp
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></EditHousingPlanAreaPopUp>
            );
          }
        })()}

        <div id="EditHousingPlanAreasScreen">
          <div id="EditHousingPlanAreasScreen-Content">
            <div className="top-bar">
              <ul>
                <li>
                  <MenuTitle
                    title={"Woongebieden bewerken"}
                    icon={<BiNetworkChart size={19} />}
                  />
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.cleanAllHousingPlanAreas(
                        this.props.updateState
                      );
                    }}
                    icon={<TbReload size={12} />}
                    className={
                      this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas.filter(
                        (area) => area.pk !== 0
                      ).length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Verwijder alles (
                    {
                      this.props.state.EditHousingPlanAreasScreen.HousingPlanAreas.filter(
                        (area) => area.pk !== 0
                      ).length
                    }
                    )
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.calculateHousingPlan(
                        this.props.updateState
                      );
                    }}
                    icon={<MdOutlineDone size={12} />}
                    className="MainBtn OK"
                  >
                    Bereken scenario
                  </MainButton>
                </li>
              </ul>
            </div>
            <div id="EditHousingPlanAreasScreen-MapHolder">
              <DrawHousingPlanAreasMap
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class ProjectStateHandler {
  // project screen
  initProjectScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Project;
    this.getProject(updateState);
    updateState(this.state);
  }

  getProject(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    const scenario = this.state.projects.find(
      (project) => project.id === this.state.selectedProject
    );
    if (scenario) {
      this.state.project.typescenario =
        scenario.scenarioList[0].scenarioType || "";
    }
    this.projectApi
      .apiProjectRetrieve({ id: this.state.selectedProject || 0 })
      .then((project) => {
        this.state.project.name = project.name;
        this.state.project.createdDate =
          project.timestampLastModified.toString();
        this.state.project.sourcedataDate = "2023";
        updateState(this.state);
      });
  }

  deleteProject(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiProjectDestroy({ id: this.state.selectedProject as number })
      .then((response) => {
        this.loadProjects(() => {
          if (this.state.projects.length > 0) {
            this.changeScreen(ScreenState.ScenarioResult, updateState);
          } else {
            this.changeScreen(ScreenState.NewProject, updateState);
          }
        });
      });
  }
}

import React from "react";
import { AppStateHandler } from "src/AppStateHandler";
import DropDown from "src/components/DropDown";
import {
  HousingTypeEnumToDropDown,
  HousingTypeEnumToIndex,
  IndexToHousingTypeEnum,
} from "src/helperFunctions";
import { AppStateType } from "src/Types";
import Button from "./Button";
import "./NewHousingPlanAreaPopUp.css";
import { TextInput, TextInputType } from "./TextInput";
interface NewHousingPlanAreaPopUpProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class NewHousingPlanAreaPopUp extends React.Component<NewHousingPlanAreaPopUpProps> {
  render() {
    return (
      <div id="NewHousingPlanAreaPopUp">
        <div id="NewHousingPlanAreaPopUp_Box">
          <div id="NewHousingPlanAreaPopUp_ContentWrapper">
            <div id="NewHousingPlanAreaPopUp_Title">
              Nieuwe Woongebied creëren
            </div>

            <div id="NewHousingPlanAreaPopUp_Section">
              <div className="NewHousingPlanAreaPopUp_Question">Naam:</div>
              <TextInput
                // style={inputStyling}
                onChange={(name) => {
                  if (name.length > 0) {
                    this.props.state.EditHousingPlanAreasScreen.newHousingPlanArea.name =
                      name;
                    this.props.updateState(this.props.state);
                  }
                }}
                placeholderColor={"#BBB"}
                placeholder={"Naam"}
                type={TextInputType.TextSpacesNumbersLines}
              ></TextInput>
              <div className="NewHousingPlanAreaPopUp_Question">
                Aantal huizen:
              </div>
              <TextInput
                // style={inputStyling}
                onChange={(number_of_houses) => {
                  const numberOfHouses = parseInt(number_of_houses, 10);
                  if (!isNaN(numberOfHouses)) {
                    this.props.state.EditHousingPlanAreasScreen.newHousingPlanArea.number_of_houses =
                      numberOfHouses;
                    this.props.updateState(this.props.state);
                  }
                }}
                placeholderColor={"#BBB"}
                placeholder={"Aantal huizen"}
                type={TextInputType.UnsignedInteger}
              ></TextInput>
              <div className="NewHousingPlanAreaPopUp_Question">Woningtype</div>
              <DropDown
                list={HousingTypeEnumToDropDown()}
                onChange={(newKey) => {
                  this.props.state.EditHousingPlanAreasScreen.newHousingPlanArea.housing_type =
                    IndexToHousingTypeEnum(newKey);
                  this.props.updateState(this.props.state);
                }}
                selectedKey={HousingTypeEnumToIndex(
                  this.props.state.EditHousingPlanAreasScreen.newHousingPlanArea
                    .housing_type
                )}
              ></DropDown>
            </div>

            <div id="NewHousingPlanAreaPopUp_SubmitButtonHolder">
              <Button
                // style={submitReportButton}
                // hoverStyle={submitReportButtonHover}
                onClick={() => {
                  this.props.stateHandler.create_HousingPlanArea(
                    this.props.state.EditHousingPlanAreasScreen
                      .newHousingPlanArea,
                    this.props.updateState
                  );
                }}
              >
                Opslaan
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

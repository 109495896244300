import { AppStateHandler } from "src/AppStateHandler";
import {
  AppStateType,
  DrawMode,
  EditedVertex,
  Road,
  ScreenState,
} from "src/Types";

export class EditNetworkStateHandler {
  initEditNetworkScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.editNetworkClearMap(updateState);

    if (this.state.selectedScenario) {
      this.scenarioApi
        .apiScenarioRetrieve({ id: this.state.selectedScenario })
        .then((response) => {
          this.editNetworkClearMap(
            updateState,
            response.projectAreaCentroidLat,
            response.projectAreaCentroidLon
          );

          this.state.screenState = ScreenState.EditNetworkScreen;
          this.state.editNetwork.selectedTopology.id = response.baseTopology;
          updateState(this.state);

          this.reloadBaseNetwork(updateState);
          this.editNetworkLoadEditedRoads(updateState);
          this.editNetworkLoadEditedVertices(updateState);
        });
    }
  }

  editNetworkLoadEditedRoads(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.roadApi
        .apiRoadList({ scenario: this.state.selectedScenario })
        .then((response) => {
          this.state.editNetwork.editedRoads = response.map((responseRoad) => {
            const road: Road = {
              id: responseRoad.id,
              gid: responseRoad.gid || null,
              edit_type: responseRoad.editType,
              speed: responseRoad.speed,
              reverse_speed: responseRoad.reverseSpeed,
              geom: {
                type: "LineString",
                coordinates: responseRoad.geom.coordinates,
              },
              source: responseRoad.source,
              target: responseRoad.target,
            };
            return road;
          });
          updateState(this.state);
        })
        .catch((error) => {
          console.log("Error while loading edited roads", error);
          this.displayAlert(
            "Bewerkte wegen konden niet geladen worden.",
            5000,
            updateState
          );
        });
    }
  }

  editNetworkLoadEditedVertices(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.vertexApi
        .apiVertexList({ scenario: this.state.selectedScenario })
        .then((response) => {
          this.state.editNetwork.editedVertices = response.map(
            (responseVertex) => {
              const vertex: EditedVertex = {
                id: responseVertex.id,
                gid: responseVertex.gid,
                geom: {
                  type: "Point",
                  coordinates: responseVertex.geom.coordinates,
                },
              };
              return vertex;
            }
          );
          updateState(this.state);
        })
        .catch((error) => {
          console.log("Error while loading edited vertices", error);
          this.displayAlert(
            "Bewerkte knooppunten konden niet geladen worden.",
            5000,
            updateState
          );
        });
    }
  }

  reloadBaseNetwork(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (
      this.state.editNetwork.map.x_min === null &&
      this.state.editNetwork.map.y_min === null &&
      this.state.editNetwork.map.x_max === null &&
      this.state.editNetwork.map.y_max === null
    ) {
      const distanceMeters = 1500;

      const earthRadius = 6378137; // Earth's radius in meters
      const latRadian = (this.state.editNetwork.map.center_lat * Math.PI) / 180;
      const metersPerDegreeLng =
        (earthRadius * Math.cos(latRadian) * 2 * Math.PI) / 360;
      const metersPerDegreeLat = (earthRadius * 2 * Math.PI) / 360;

      const deltaX = distanceMeters / metersPerDegreeLng;
      const deltaY = distanceMeters / metersPerDegreeLat;

      this.state.editNetwork.map.x_max =
        this.state.editNetwork.map.center_lng + deltaX;
      this.state.editNetwork.map.x_min =
        this.state.editNetwork.map.center_lng - deltaX;
      this.state.editNetwork.map.y_max =
        this.state.editNetwork.map.center_lat + deltaY;
      this.state.editNetwork.map.y_min =
        this.state.editNetwork.map.center_lat - deltaY;
      updateState(this.state);
    }

    if (this.state.editNetwork.map.zoom >= 14.5) {
      // Get base network
      if (
        this.state.editNetwork.selectedTopology.id &&
        this.state.editNetwork.map.x_min &&
        this.state.editNetwork.map.y_min &&
        this.state.editNetwork.map.x_max &&
        this.state.editNetwork.map.y_max
      ) {
        const newId = this.addToAlerts("Netwerk aan het laden.", updateState);
        this.topologyApi
          .apiTopologyRetrieve({
            id: this.state.editNetwork.selectedTopology.id,
            xMax: this.state.editNetwork.map.x_max,
            xMin: this.state.editNetwork.map.x_min,
            yMax: this.state.editNetwork.map.y_max,
            yMin: this.state.editNetwork.map.y_min,
          })
          .then((response) => {
            this.removeFromAlerts(newId, updateState);
            this.state.editNetwork.selectedTopology.roads = response.roads;
            this.state.editNetwork.selectedTopology.vertices =
              response.vertices;
            this.state.editNetwork.selectedTopology.boundingBox =
              response.boundingBox;
            updateState(this.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.displayAlert(
        "Zoom in en klik op herlaad om het basis netwerk te bekijken.",
        5000,
        updateState
      );
    }
  }

  editNetworkMapMoved(
    this: AppStateHandler,
    zoom: number,
    center_lat: number,
    center_lng: number,
    x_min: number,
    y_min: number,
    x_max: number,
    y_max: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.editNetwork.map.zoom = zoom;
    this.state.editNetwork.map.center_lat = center_lat;
    this.state.editNetwork.map.center_lng = center_lng;
    this.state.editNetwork.map.x_min = x_min;
    this.state.editNetwork.map.y_min = y_min;
    this.state.editNetwork.map.x_max = x_max;
    this.state.editNetwork.map.y_max = y_max;
    updateState(this.state);

    // // Execute 2 seconds after move
    // if (this.mapMoveTimeout) {
    //   clearTimeout(this.mapMoveTimeout);
    // }
    // this.mapMoveTimeout = setTimeout(() => {
    //   // Update database project with new lat, lon and zoom
    //   if (this.state.dataState.selectedProject) {
    //     this.networking.updateMapPosition(
    //       this.state.dataState.selectedProject,
    //       center_lat,
    //       center_lng,
    //       zoom,
    //       () => {},
    //       () => {
    //         const projectIndex = this.state.dataState.projectList.findIndex(
    //           (object) => {
    //             return object.id === this.state.dataState.selectedProject;
    //           }
    //         );
    //         if (projectIndex > -1) {
    //           this.state.dataState.projectList[projectIndex].map_lat =
    //             center_lat;
    //           this.state.dataState.projectList[projectIndex].map_lon =
    //             center_lng;
    //           this.state.dataState.projectList[projectIndex].map_zoom = zoom;
    //           updateState(this.state);
    //         }
    //         console.log("Project: Saved map position");
    //       },
    //       () => {
    //         //sign out
    //         this.state = JSON.parse(initialStateString);
    //         updateState(this.state);
    //       }
    //     );
    //   }
    // }, 2000);
  }

  editNetworkMapChangeDrawMode(
    this: AppStateHandler,
    newDrawMode: DrawMode,
    updateState: (newState: AppStateType) => void
  ) {
    if (newDrawMode === DrawMode.AddRoad) {
      this.displayAlert(
        "Klik op een knooppunt om te starten.",
        5000,
        updateState
      );
    } else if (newDrawMode === DrawMode.EditRoad) {
      this.displayAlert(
        "Klik op een weg van het basis netwerk om het op te waarderen.",
        5000,
        updateState
      );
    } else if (newDrawMode === DrawMode.DeleteRoad) {
      this.displayAlert(
        "Klik op een weg of knooppunt om het te verwijderen.",
        5000,
        updateState
      );
    } else if (newDrawMode === DrawMode.AddVertex) {
      this.displayAlert(
        "Klik op de kaart om een knooppunt toe te voegen.",
        5000,
        updateState
      );
    }
    // else if (newDrawMode === DrawMode.AddPoint) {
    //   this.displayAlert(
    //     "Klik op de kaart om een punt toe te voegen.",
    //     5000,
    //     updateState
    //   );
    // } else if (newDrawMode === DrawMode.DeletePoint) {
    //   this.displayAlert(
    //     "Klik op een punt om het te verwijderen.",
    //     5000,
    //     updateState
    //   );
    // }
    this.state.editNetwork.drawMode = newDrawMode;
    updateState(this.state);
  }

  editNetworkStartCalculation(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.scenarioApi
        .apiScenarioCalcCreate({ id: this.state.selectedScenario })
        .then((result) => {
          this.displayAlert("Scenario berekening gestart.", 5000, updateState);
          this.changeScreen(ScreenState.ScenarioResult, updateState);
        })
        .catch((error) => {
          console.log("Error while starting calculation", error);
          this.displayAlert(
            "Fout tijdens het starten van de scenario berekening.",
            5000,
            updateState
          );
        });
    }
  }

  editNetworkAddVertex(
    this: AppStateHandler,
    newVertex: EditedVertex,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.state.editNetwork.editedVertices.push(newVertex);
      updateState(this.state);

      this.vertexApi
        .apiVertexCreate({
          vertexCreateRequest: {
            scenario: this.state.selectedScenario,
            geom: newVertex.geom,
          },
        })
        .then((result) => {
          // Update id
          newVertex.id = result.id;
          newVertex.gid = result.gid;
          const vertexIndex = this.state.editNetwork.editedVertices.findIndex(
            (object) => {
              return (
                JSON.stringify(object.geom) === JSON.stringify(newVertex.geom)
              );
            }
          );
          if (vertexIndex > -1) {
            this.state.editNetwork.editedVertices.splice(vertexIndex, 1);
          }
          this.state.editNetwork.editedVertices.push(newVertex);

          this.displayAlert("Knooppunt opgeslagen.", 5000, updateState);
        })
        .catch((error) => {
          console.log("Error while adding vertex", error);
          this.displayAlert(
            "De knoop kon niet correct opgeslagen worden.",
            5000,
            updateState
          );
          // Delete vertex
          const vertexIndex = this.state.editNetwork.editedVertices.findIndex(
            (object) => {
              return (
                JSON.stringify(object.geom) === JSON.stringify(newVertex.geom)
              );
            }
          );
          if (vertexIndex > -1) {
            this.state.editNetwork.editedVertices.splice(vertexIndex, 1);
          }
        });
    }
  }

  editNetworkAddRoad(
    this: AppStateHandler,
    newRoad: Road,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.state.editNetwork.editedRoads.push(newRoad);
      updateState(this.state);

      this.roadApi
        .apiRoadCreate({
          roadCreateUpdateRequest: {
            scenario: this.state.selectedScenario,
            geom: newRoad.geom,
            source: newRoad.source,
            target: newRoad.target,
            speed: newRoad.speed,
            reverseSpeed: newRoad.reverse_speed,
          },
        })
        .then((result) => {
          // Update id
          newRoad.id = result.id;
          const roadIndex = this.state.editNetwork.editedRoads.findIndex(
            (object) => {
              return (
                JSON.stringify(object.geom) === JSON.stringify(newRoad.geom)
              );
            }
          );
          if (roadIndex > -1) {
            this.state.editNetwork.editedRoads.splice(roadIndex, 1);
          }
          this.state.editNetwork.editedRoads.push(newRoad);

          this.displayAlert("Weg opgeslagen.", 5000, updateState);
        })
        .catch((error) => {
          console.log("Error while adding road", error);
          this.displayAlert(
            "De weg kon niet correct opgeslagen worden.",
            5000,
            updateState
          );
          // Delete road
          const roadIndex = this.state.editNetwork.editedRoads.findIndex(
            (object) => {
              return (
                JSON.stringify(object.geom) === JSON.stringify(newRoad.geom)
              );
            }
          );
          if (roadIndex > -1) {
            this.state.editNetwork.editedRoads.splice(roadIndex, 1);
          }
        });
    }
  }

  editNetworkEditRoad(
    this: AppStateHandler,
    roadToUpgrade: Road,
    shiftKey: boolean,
    updateState: (newState: AppStateType) => void
  ) {
    if (roadToUpgrade.gid) {
      // Add road to a temporary list so we know which roads to upgrade
      this.state.editNetwork.roadsToUpgrade.gids.add(roadToUpgrade.gid);
      this.state.editNetwork.roadsToUpgrade.speed = `${roadToUpgrade.speed}`;
      this.state.editNetwork.roadsToUpgrade.reverseSpeed = `${roadToUpgrade.reverse_speed}`;

      // Add road to the edited roads list
      const roadIndex = this.state.editNetwork.editedRoads.findIndex(
        (object) => {
          return object.gid === roadToUpgrade.gid;
        }
      );
      if (roadIndex > -1) {
        this.state.editNetwork.editedRoads.splice(roadIndex, 1);
      }
      this.state.editNetwork.editedRoads.push(roadToUpgrade);

      // Only show the popup if the last road is selected
      if (shiftKey === false) {
        this.state.editNetwork.editSpeedPopUpVisable = true;
      }
      updateState(this.state);
    }
  }

  editNetworkUpdateSpeed(
    this: AppStateHandler,
    formData: { speed?: string | null; reverseSpeed?: string | null },
    updateState: (newState: AppStateType) => void
  ) {
    if (formData.speed !== undefined) {
      this.state.editNetwork.roadsToUpgrade.speed = `${formData.speed}`;
    }
    if (formData.reverseSpeed !== undefined) {
      this.state.editNetwork.roadsToUpgrade.reverseSpeed = `${formData.reverseSpeed}`;
    }
    updateState(this.state);
  }

  editNetworkSaveRoad(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    const speed = parseFloat(this.state.editNetwork.roadsToUpgrade.speed) || -1;
    const reverseSpeed =
      parseFloat(this.state.editNetwork.roadsToUpgrade.reverseSpeed) || -1;

    if (this.state.editNetwork.roadsToUpgrade.gids.size > 0) {
      // Close the speed popup first
      this.state.editNetwork.editSpeedPopUpVisable = false;
      updateState(this.state);

      // Loop over the road gid's
      this.state.editNetwork.roadsToUpgrade.gids.forEach((newRoadGid) => {
        if (this.state.selectedScenario) {
          // Find road in the editedRoads list
          const roadIndex = this.state.editNetwork.editedRoads.findIndex(
            (object) => {
              return object.gid === newRoadGid;
            }
          );
          if (roadIndex > -1) {
            const editedRoad = this.state.editNetwork.editedRoads[roadIndex];

            // Save the road on the server
            this.roadApi
              .apiRoadUpdate({
                id: newRoadGid,
                roadCreateUpdateRequest: {
                  scenario: this.state.selectedScenario,
                  geom: editedRoad.geom,
                  source: editedRoad.source,
                  target: editedRoad.target,
                  speed: speed,
                  reverseSpeed: reverseSpeed,
                },
              })
              .then((response) => {
                // Update the id in the frontend
                editedRoad.id = response.id;
                const roadIndex = this.state.editNetwork.editedRoads.findIndex(
                  (object) => {
                    return object.gid === editedRoad.gid;
                  }
                );
                if (roadIndex > -1) {
                  this.state.editNetwork.editedRoads.splice(roadIndex, 1);
                }
                this.state.editNetwork.editedRoads.push(editedRoad);

                this.displayAlert("Weg opgewaardeerd.", 5000, updateState);
              })
              .catch((error) => {
                this.displayAlert(
                  "De weg kon niet correct opgeslagen worden.",
                  5000,
                  updateState
                );
              });
          }
        }
      });
      this.state.editNetwork.roadsToUpgrade.gids.clear();
    }
  }

  editNetworkDeleteBaseRoad(
    this: AppStateHandler,
    roadGid: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.displayAlert(
      "Wegen van het basisnetwerk kunnen op dit moment niet verwijderd worden.",
      5000,
      updateState
    );
  }

  editNetworkDeleteRoad(
    this: AppStateHandler,
    roadId: number | null,
    updateState: (newState: AppStateType) => void
  ) {
    if (roadId) {
      // remove from state
      const roadIndex = this.state.editNetwork.editedRoads.findIndex(
        (object) => {
          return object.id === roadId;
        }
      );
      const deletedRoad = this.state.editNetwork.editedRoads[roadIndex];
      if (roadIndex > -1) {
        this.state.editNetwork.editedRoads.splice(roadIndex, 1);
      }
      updateState(this.state);

      this.roadApi
        .apiRoadDestroy({ id: roadId })
        .then((response) => {
          this.displayAlert("Weg verwijderd.", 5000, updateState);
        })
        .catch((error) => {
          console.log("Error while deleting road", error);
          // TODO: Check if user is signed out
          // //sign out
          // this.state = JSON.parse(initialStateString);
          // updateState(this.state);
          if (error instanceof SyntaxError) {
            this.displayAlert("Weg verwijderd.", 5000, updateState);
          } else {
            this.state.editNetwork.editedRoads.push(deletedRoad);
            this.displayAlert(
              "Weg kan niet worden verwijderd. Probeer later opnieuw.",
              5000,
              updateState
            );
          }
        });
    } else {
      this.displayAlert(
        "Weg kan niet worden verwijderd. Probeer later opnieuw.",
        5000,
        updateState
      );
    }
  }

  async editNetworkDeleteEditedVertex(
    this: AppStateHandler,
    editedVertexId: number | null,
    updateState: (newState: AppStateType) => void
  ) {
    if (editedVertexId) {
      // Remove vertex from state
      const vertexIndex = this.state.editNetwork.editedVertices.findIndex(
        (object) => {
          return object.id === editedVertexId;
        }
      );
      const deletedVertex = this.state.editNetwork.editedVertices[vertexIndex];
      if (vertexIndex > -1) {
        this.state.editNetwork.editedVertices.splice(vertexIndex, 1);
      }
      updateState(this.state);

      // Find edited roads connected to this vertex
      let roadsToDelete: Road[] = [];
      this.state.editNetwork.editedRoads.forEach((road) => {
        if (
          (road.source === deletedVertex.gid ||
            road.target === deletedVertex.gid) &&
          road.id !== null
        ) {
          roadsToDelete.push(road);
        }
      });

      // Remove these roads from the state
      roadsToDelete.forEach((road) => {
        const roadIndex = this.state.editNetwork.editedRoads.findIndex(
          (object) => {
            return object.id === road.id!;
          }
        );
        if (roadIndex > -1) {
          this.state.editNetwork.editedRoads.splice(roadIndex, 1);
        }
      });
      updateState(this.state);

      // Delete all related roads on the server
      const results = await Promise.allSettled(
        roadsToDelete.map(async (road) => {
          return this.roadApi.apiRoadDestroy({ id: road.id! });
        })
      );

      // Check if deletion was successfull
      if (
        roadsToDelete.length === 0 ||
        results.every(
          (result) =>
            result.status === "fulfilled" ||
            (result.status === "rejected" &&
              result.reason instanceof SyntaxError)
        )
      ) {
        // Remove vertex from server
        this.vertexApi
          .apiVertexDestroy({ id: editedVertexId })
          .then((response) => {
            this.displayAlert("Knooppunt verwijderd.", 5000, updateState);
          })
          .catch((error) => {
            console.log("Error while deleting edited vertex", error);
            // TODO: Check if user is signed out
            // //sign out
            // this.state = JSON.parse(initialStateString);
            // updateState(this.state);
            if (error instanceof SyntaxError) {
              this.displayAlert("Knooppunt verwijderd.", 5000, updateState);
            } else {
              this.state.editNetwork.editedVertices.push(deletedVertex);
              this.displayAlert(
                "Knooppunt kan niet worden verwijderd. Probeer later opnieuw.",
                5000,
                updateState
              );
            }
          });
      } else {
        // At least one failed
        this.state.editNetwork.editedVertices.push(deletedVertex);
        results.forEach((result, index) => {
          if (
            result.status === "rejected" &&
            !(result.reason instanceof SyntaxError)
          ) {
            this.state.editNetwork.editedRoads.push(roadsToDelete[index]);
          }
        });
        this.displayAlert(
          "Een van de wegen kan niet worden verwijderd. Probeer later opnieuw.",
          5000,
          updateState
        );
      }
    }
  }

  editNetworkClearMap(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void,
    latitude: number = 52.0898,
    longlitude: number = 5.10978
  ) {
    this.state.editNetwork = {
      selectedTopology: {
        id: null,
        roads: [],
        vertices: [],
        boundingBox: null,
      },
      map: {
        zoom: 16.0,
        center_lat: latitude,
        center_lng: longlitude,
        x_min: null,
        y_min: null,
        x_max: null,
        y_max: null,
        update_map: false,
      },
      drawMode: DrawMode.Off,
      editedRoads: [],
      roadsToUpgrade: {
        gids: new Set<number>(),
        speed: "",
        reverseSpeed: "",
      },
      editedVertices: [],
      editSpeedPopUpVisable: false,
      roadInfo: [],
    };
    updateState(this.state);
  }

  updateRoadInfo(
    this: AppStateHandler,
    roadInfo: { attribute: string; value: string }[],
    updateState: (newState: AppStateType) => void
  ) {
    this.state.editNetwork.roadInfo = roadInfo;
    updateState(this.state);
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.10.10.10.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Destination } from './Destination';
import {
    DestinationFromJSON,
    DestinationFromJSONTyped,
    DestinationToJSON,
} from './Destination';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * 
 * @export
 * @interface DestinationGroupCreateRequest
 */
export interface DestinationGroupCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DestinationGroupCreateRequest
     */
    name: string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof DestinationGroupCreateRequest
     */
    type: TypeEnum;
    /**
     * 
     * @type {Array<Destination>}
     * @memberof DestinationGroupCreateRequest
     */
    destinations: Array<Destination>;
}

/**
 * Check if a given object implements the DestinationGroupCreateRequest interface.
 */
export function instanceOfDestinationGroupCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "destinations" in value;

    return isInstance;
}

export function DestinationGroupCreateRequestFromJSON(json: any): DestinationGroupCreateRequest {
    return DestinationGroupCreateRequestFromJSONTyped(json, false);
}

export function DestinationGroupCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationGroupCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': TypeEnumFromJSON(json['type']),
        'destinations': ((json['destinations'] as Array<any>).map(DestinationFromJSON)),
    };
}

export function DestinationGroupCreateRequestToJSON(value?: DestinationGroupCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': TypeEnumToJSON(value.type),
        'destinations': ((value.destinations as Array<any>).map(DestinationToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.10.10.10.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyBoundingBox } from './TopologyBoundingBox';
import {
    TopologyBoundingBoxFromJSON,
    TopologyBoundingBoxFromJSONTyped,
    TopologyBoundingBoxToJSON,
} from './TopologyBoundingBox';
import type { TopologyRoad } from './TopologyRoad';
import {
    TopologyRoadFromJSON,
    TopologyRoadFromJSONTyped,
    TopologyRoadToJSON,
} from './TopologyRoad';
import type { TopologyVertice } from './TopologyVertice';
import {
    TopologyVerticeFromJSON,
    TopologyVerticeFromJSONTyped,
    TopologyVerticeToJSON,
} from './TopologyVertice';

/**
 * 
 * @export
 * @interface TopologyRetreiveResponse
 */
export interface TopologyRetreiveResponse {
    /**
     * 
     * @type {Array<TopologyRoad>}
     * @memberof TopologyRetreiveResponse
     */
    roads: Array<TopologyRoad>;
    /**
     * 
     * @type {Array<TopologyVertice>}
     * @memberof TopologyRetreiveResponse
     */
    vertices: Array<TopologyVertice>;
    /**
     * 
     * @type {TopologyBoundingBox}
     * @memberof TopologyRetreiveResponse
     */
    boundingBox: TopologyBoundingBox;
}

/**
 * Check if a given object implements the TopologyRetreiveResponse interface.
 */
export function instanceOfTopologyRetreiveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roads" in value;
    isInstance = isInstance && "vertices" in value;
    isInstance = isInstance && "boundingBox" in value;

    return isInstance;
}

export function TopologyRetreiveResponseFromJSON(json: any): TopologyRetreiveResponse {
    return TopologyRetreiveResponseFromJSONTyped(json, false);
}

export function TopologyRetreiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyRetreiveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roads': ((json['roads'] as Array<any>).map(TopologyRoadFromJSON)),
        'vertices': ((json['vertices'] as Array<any>).map(TopologyVerticeFromJSON)),
        'boundingBox': TopologyBoundingBoxFromJSON(json['bounding_box']),
    };
}

export function TopologyRetreiveResponseToJSON(value?: TopologyRetreiveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roads': ((value.roads as Array<any>).map(TopologyRoadToJSON)),
        'vertices': ((value.vertices as Array<any>).map(TopologyVerticeToJSON)),
        'bounding_box': TopologyBoundingBoxToJSON(value.boundingBox),
    };
}


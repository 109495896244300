/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.10.10.10.26
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ACCESSIBILITY_IN_VIEW` - Accessibility In View
 * * `BICYCLE_ORIENTED_DEVELOPMENT` - Bicycle Oriented Development
 * * `PUBLIC_TRANSPORT_STATION_AREAS` - Public Transport Station Areas
 * * `STUDENTS_ON_THE_MAP` - Students On The Map
 * @export
 */
export const ScenarioTypeEnum = {
    AccessibilityInView: 'ACCESSIBILITY_IN_VIEW',
    BicycleOrientedDevelopment: 'BICYCLE_ORIENTED_DEVELOPMENT',
    PublicTransportStationAreas: 'PUBLIC_TRANSPORT_STATION_AREAS',
    StudentsOnTheMap: 'STUDENTS_ON_THE_MAP'
} as const;
export type ScenarioTypeEnum = typeof ScenarioTypeEnum[keyof typeof ScenarioTypeEnum];


export function ScenarioTypeEnumFromJSON(json: any): ScenarioTypeEnum {
    return ScenarioTypeEnumFromJSONTyped(json, false);
}

export function ScenarioTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioTypeEnum {
    return json as ScenarioTypeEnum;
}

export function ScenarioTypeEnumToJSON(value?: ScenarioTypeEnum | null): any {
    return value as any;
}


import { AppStateHandler } from "../AppStateHandler";
import { AppStateType, ScreenState } from "../Types";

export class ProjectBarStateHandler {
  loadProjects(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiProjectList()
      .then((response) => {
        this.state.projects = response;

        // Select first project and first scenario
        if (this.state.projects.length > 0) {
          this.state.selectedProject = this.state.projects[0].id;
          if (this.state.projects[0].scenarioList.length > 0) {
            this.state.selectedScenario =
              this.state.projects[0].scenarioList[0].id;
          }
        }
        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while loading projects", error);
      });
  }

  changeSelectedProject(
    this: AppStateHandler,
    selectedProject: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = selectedProject;
    this.state.selectedScenario = null;
    updateState(this.state);
    this.changeScreen(ScreenState.Project, updateState);
  }

  changeSelectedScenario(
    this: AppStateHandler,
    selectedProject: number,
    selectedScenario: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = selectedProject;
    this.state.selectedScenario = selectedScenario;
    updateState(this.state);
    this.changeScreen(ScreenState.ScenarioResult, updateState);
  }

  projectBarCreateNewScenario(
    this: AppStateHandler,
    forProject: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = forProject;
    this.state.selectedScenario = null;
    updateState(this.state);
    this.changeScreen(ScreenState.NewScenario, updateState);
  }
}

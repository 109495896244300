import {
  HousingTypeEnum,
  ProjectListResponse,
  ProjectListScenarioResponse,
  TypeEnum,
} from "./client/lib/models";
import { DropDownListItem } from "./components/DropDown";

export const getSelectedScenario = (
  projectList: ProjectListResponse[],
  selectedScenarioId: number | null
): ProjectListScenarioResponse | null => {
  if (selectedScenarioId !== null) {
    let scenarioIndex = -1;
    let projectIndex = -1;
    for (projectIndex = 0; projectIndex < projectList.length; projectIndex++) {
      scenarioIndex = projectList[projectIndex].scenarioList.findIndex(
        (scenario) => {
          return scenario.id === selectedScenarioId;
        }
      );

      if (scenarioIndex > -1) {
        break;
      }
    }

    if (scenarioIndex > -1) {
      return projectList[projectIndex].scenarioList[scenarioIndex];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

// Functions to convert TypeEnum enum to DropDownListItems
export const TravelTimeDecayTypeEnumToDropDown = (): DropDownListItem[] => {
  return (Object.keys(TypeEnum) as Array<keyof typeof TypeEnum>).map(
    (key, index) => {
      let displayName = "";
      switch (TypeEnum[key]) {
        case TypeEnum.Intercitytrain:
          displayName = "Intercity stations";
          break;
        case TypeEnum.Regionaltrain:
          displayName = "Regionale treinstations";
          break;
        case TypeEnum.Bus:
          displayName = "Bus haltes";
          break;
        case TypeEnum.Tram:
          displayName = "Tram haltes";
          break;
        case TypeEnum.Metro:
          displayName = "Metro stations";
          break;
        case TypeEnum.Citycenter:
          displayName = "Stadscentra";
          break;
        case TypeEnum.Shoppingcenter:
          displayName = "Winkelcentra";
          break;
        case TypeEnum.Smallshop:
          displayName = "Kleine op afzonderlijke winkels";
          break;
        case TypeEnum.Elementaryschool:
          displayName = "Basisscholen";
          break;
        case TypeEnum.Highschool:
          displayName = "Middelbare scholen";
          break;
        case TypeEnum.Commuting:
          displayName = "Werklocaties";
          break;
        default:
          break;
      }
      return {
        key: index,
        name: displayName,
        disabled: false,
      };
    }
  );
};
export const TravelTimeDecayTypeEnumToIndex = (state: TypeEnum): number => {
  return Object.values(TypeEnum).indexOf(state);
};
export const IndexToTravelTimeDecayTypeEnum = (index: number): TypeEnum => {
  const key = Object.keys(TypeEnum)[index] as keyof typeof TypeEnum;
  return TypeEnum[key];
};

// Functions to convert HousingTypeEnum enum to DropDownListItems
export const HousingTypeEnumToDropDown = (): DropDownListItem[] => {
  return (
    Object.keys(HousingTypeEnum) as Array<keyof typeof HousingTypeEnum>
  ).map((key, index) => {
    let displayName = "";
    switch (HousingTypeEnum[key]) {
      case HousingTypeEnum.Apartment:
        displayName = "Appartementen";
        break;
      case HousingTypeEnum.DetachedHouse:
        displayName = "Vrijstaande huizen ";
        break;
      case HousingTypeEnum.SemiDetachedHouse:
        displayName = "Halfvrijstaande huizen";
        break;
      case HousingTypeEnum.TarracedHouse:
        displayName = "Rijtjeshuizen";
        break;
      default:
        break;
    }
    return {
      key: index,
      name: displayName,
      disabled: false,
    };
  });
};
export const HousingTypeEnumToIndex = (state: HousingTypeEnum): number => {
  return Object.values(HousingTypeEnum).indexOf(state);
};
export const IndexToHousingTypeEnum = (index: number): HousingTypeEnum => {
  const key = Object.keys(HousingTypeEnum)[
    index
  ] as keyof typeof HousingTypeEnum;
  return HousingTypeEnum[key];
};

import React from "react";
import { BiNetworkChart, BiSolidHomeAlt2 } from "react-icons/bi";
import { FaRegFileImage } from "react-icons/fa";
import { GoXCircleFill } from "react-icons/go";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { RiRoadMapLine } from "react-icons/ri";
import { TbCheck, TbHistory, TbReload } from "react-icons/tb";
import { TiCompass } from "react-icons/ti";
import { ContentTypeEnum } from "src/client/lib/models";
import BODGraph from "src/components/BODGraph";
import BODGraphDiff from "src/components/BODGraphDiff";
import HousingPlanGraph from "src/components/HousingPlanGraph";
import MainButton from "src/components/MainButton";
import MapPupilDensityNetwork from "src/components/MapPupilDensityNetwork";
import MapResultBlockHeatmap from "src/components/MapResultBlockHeatmap";
import MenuTitle from "src/components/MenuTitle";
import MunicipalityGraph from "src/components/MunicipalityGraph";
import MunicipalityGraphDiff from "src/components/MunicipalityGraphDiff";
import { ResultBlockMap } from "src/components/ResultBlockMap";
import { ResultBlockOverviewMap } from "src/components/ResultBlockOverviewMap";
import Table from "src/components/ResultBlockTable";
import SectionTitle from "src/components/SectionTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";
import { CategoryEnum, ScenarioTypeEnum } from "../../client/lib/models";
import "./ScenarioResultScreen.css";

interface ScenarioResultScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ScenarioResultScreen extends React.Component<ScenarioResultScreenProps> {
  render() {
    return (
      <div id="ScenarioResultScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle
                title={
                  (this.props.state.scenarioScreen.name
                    ? this.props.state.scenarioScreen.name
                    : "") +
                  " | " +
                  this.props.state.scenarioScreen.scenarioTypeInDutch
                }
                icon={<RiRoadMapLine size={19} />}
              />
            </li>

            {(() => {
              if (!this.props.state.scenarioScreen.calculationFinished) {
                return (
                  <li>
                    <div className="spinner">
                      <div className="pin"></div>
                    </div>
                  </li>
                );
              } else {
                if (this.props.state.scenarioScreen.upToDate) {
                  return (
                    <li>
                      <div className="upToDate">
                        <TbCheck />
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <div className="upToDate outOfDate">
                        <TbHistory />
                      </div>
                    </li>
                  );
                }
              }
            })()}

            {(() => {
              if (
                this.props.state.scenarioScreen.category ===
                  CategoryEnum.CurrentScenario &&
                this.props.state.scenarioScreen.scenarioType ===
                  ScenarioTypeEnum.BicycleOrientedDevelopment
              ) {
                return (
                  <>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.scenarioRestartCalculation(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className={
                          this.props.state.scenarioScreen.calculationFinished
                            ? "MainBtn OK"
                            : "MainBtn disabled"
                        }
                      >
                        Bereken opnieuw
                      </MainButton>
                    </li>
                  </>
                );
              } else if (
                this.props.state.scenarioScreen.category ===
                  CategoryEnum.NewScenario &&
                this.props.state.scenarioScreen.scenarioType ===
                  ScenarioTypeEnum.BicycleOrientedDevelopment
              ) {
                return (
                  <>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.EditNetworkScreen,
                            this.props.updateState
                          );
                        }}
                        icon={<BiNetworkChart size={12} />}
                        className="MainBtn OK"
                      >
                        Netwerk bewerken
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.EditHousingPlanAreasScreen,
                            this.props.updateState
                          );
                        }}
                        icon={<BiSolidHomeAlt2 size={12} />}
                        className="MainBtn OK"
                      >
                        Woongebieden bewerken
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.scenarioRestartCalculation(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className={
                          this.props.state.scenarioScreen.calculationFinished
                            ? "MainBtn OK"
                            : "MainBtn disabled"
                        }
                      >
                        Bereken opnieuw
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.deleteScenario(
                            this.props.updateState
                          );
                        }}
                        icon={<GoXCircleFill size={12} />}
                        className="MainBtn Danger"
                      >
                        Scenario verwijderen
                      </MainButton>
                    </li>
                  </>
                );
              }
            })()}
          </ul>
        </div>
        <div id="ScenarioResultScreen-ContentHolder">
          <SectionTitle></SectionTitle>

          <div id="ScenarioResultScreen-ResultHolder">
            {this.props.state.scenarioScreen.resultBlocks
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((resultBlock) => {
                return (
                  <div
                    key={resultBlock.id}
                    className="ScenarioResultScreen-ResultItem"
                  >
                    <div className="ScenarioResultScreen-ResultItemTitle">
                      {resultBlock.title}
                    </div>

                    {(() => {
                      if (
                        resultBlock.contentType &&
                        (resultBlock.contentType ===
                          ContentTypeEnum.Isochrone ||
                          resultBlock.contentType ===
                            ContentTypeEnum.ScenarioChanges)
                      ) {
                        return (
                          <ResultBlockMap
                            geoJsonString={resultBlock.content}
                            mapType={resultBlock.contentType}
                          ></ResultBlockMap>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType === ContentTypeEnum.Overview
                      ) {
                        return (
                          <ResultBlockOverviewMap
                            geoJsonString={resultBlock.content}
                            mapType={resultBlock.contentType}
                          ></ResultBlockOverviewMap>
                        );
                      } else if (
                        resultBlock.contentType &&
                        (resultBlock.contentType === ContentTypeEnum.Heatmap ||
                          resultBlock.contentType ===
                            ContentTypeEnum.HeatmapDiff)
                      ) {
                        return (
                          <MapResultBlockHeatmap
                            geoJsonString={resultBlock.content}
                            mapType={resultBlock.contentType}
                            overviewGeoJsonString={
                              this.props.state.scenarioScreen.resultBlocks[0]
                                .content
                            }
                          ></MapResultBlockHeatmap>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType ===
                          ContentTypeEnum.PupilDensityNetwork
                      ) {
                        return (
                          <MapPupilDensityNetwork
                            geoJsonString={resultBlock.content}
                          ></MapPupilDensityNetwork>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType === ContentTypeEnum.Table
                      ) {
                        return <Table jsonString={resultBlock.content}></Table>;
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType === ContentTypeEnum.Graph
                      ) {
                        return (
                          <BODGraph dataString={resultBlock.content}></BODGraph>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType === ContentTypeEnum.GraphDiff
                      ) {
                        return (
                          <BODGraphDiff
                            dataString={resultBlock.content}
                          ></BODGraphDiff>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType ===
                          ContentTypeEnum.MunicipalityGraph
                      ) {
                        return (
                          <MunicipalityGraph
                            dataString={resultBlock.content}
                          ></MunicipalityGraph>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType ===
                          ContentTypeEnum.MunicipalityGraphDiff
                      ) {
                        return (
                          <MunicipalityGraphDiff
                            dataString={resultBlock.content}
                          ></MunicipalityGraphDiff>
                        );
                      } else if (
                        resultBlock.contentType &&
                        resultBlock.contentType ===
                          ContentTypeEnum.HousingPlanGraph
                      ) {
                        return (
                          <HousingPlanGraph
                            dataString={resultBlock.content}
                          ></HousingPlanGraph>
                        );
                      }
                    })()}
                    <div className="ScenarioResultScreen-Tab">
                      <div className="tooltip">
                        <button
                          className="ScenarioResultScreen-Icon-button"
                          onClick={() => {
                            this.props.stateHandler.displayAlert(
                              resultBlock.description || resultBlock.title,
                              7000,
                              this.props.updateState
                            );
                          }}
                        >
                          <IoIosInformationCircleOutline size={15} />
                        </button>
                        <span className="tooltiptext">Informatie</span>
                      </div>

                      {(() => {
                        const contentTypesExport: ContentTypeEnum[] = [
                          ContentTypeEnum.Heatmap,
                          ContentTypeEnum.Graph,
                          ContentTypeEnum.GraphDiff,
                          ContentTypeEnum.HeatmapDiff,
                          ContentTypeEnum.MunicipalityGraph,
                        ];

                        if (
                          contentTypesExport.includes(
                            resultBlock.contentType as ContentTypeEnum
                          )
                        ) {
                          return (
                            <div className="tooltip">
                              <button
                                className="ScenarioResultScreen-Icon-button"
                                onClick={() => {
                                  this.props.stateHandler.exportBlock(
                                    resultBlock.id,
                                    resultBlock.title,
                                    this.props.updateState
                                  );
                                }}
                              >
                                <FaRegFileImage size={15} />
                              </button>
                              <span className="tooltiptext">Exporteren</span>
                            </div>
                          );
                        }

                        return null; // Add this line to handle the case when neither condition is met
                      })()}
                      {(() => {
                        const contentTypeLegend: ContentTypeEnum[] = [
                          ContentTypeEnum.Overview,
                          ContentTypeEnum.Isochrone,
                          ContentTypeEnum.Heatmap,
                          ContentTypeEnum.HeatmapDiff,
                          ContentTypeEnum.PupilDensityNetwork,
                        ];

                        if (
                          contentTypeLegend.includes(
                            resultBlock.contentType as ContentTypeEnum
                          )
                        ) {
                          return (
                            <div className="tooltip">
                              <button
                                className="ScenarioResultScreen-Icon-button"
                                onClick={() => {
                                  this.props.state.Legend =
                                    resultBlock.legend as any;
                                  this.props.state.popUpState =
                                    PopUpState.LEGEND;
                                  this.props.updateState(this.props.state);
                                }}
                              >
                                <TiCompass size={15} />
                              </button>
                              <span className="tooltiptext">Legenda</span>
                            </div>
                          );
                        }
                        return null; // Add this line to handle the case when neither condition is met
                      })()}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
